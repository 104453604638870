export default {
  m: {
      connect:'Sambungkan dompet',
      wallet:'Silakan menginstal dompet metamask',
      no_connect: 'Dompet tidak terhubung',
      pledgeSuccess:  "Promise success",
      share_copy:"Disalin",
      bind_success:"Bind sukses",
      address_error:"Alamat error",
      home:"Homepage",
      mining:"Mining",
      me:"mine",
      lan:"Bahasa",
      invite_num:"Jumlah orang yang sah",
    invest:"Investasi",
    profit: "Kembalian",
    release:"Untuk dibebaskan",
    cash:'Withdrawable',
    price:"Harga",
    team_invest:"Team investment",
    pool:"Casting Pool",
    circulation:"Cirkulasi",
    small_card:"Kartu Vanguard",
    big_card:"Genesis Card",
    xq:"Proyek",
    account:"My account",
    max:"Maximum castable WuDao",
    nft:"Kartuku",
    myinvite:"Undanganku",
    tjz:"Recommended by",
    ztuser:"Pengguna Dorong Langsung",
    view:"Lihat",
    nouser:"belum ada pengguna yang direkomendasikan",
    copy:"Disalin",
    ptjs:"Perkenalan Platform",
    ptjs2:"RousePro adalah protokol keuangan yang tidak dikelola, bebas kepercayaan, disentralisasi pada rantai keuangan yang berkolaborasi dengan protokol likiditas PancakeSwap V3 untuk menyediakan layanan dan dukungan, serta banyak blockchain dan Dex kompatibilitas dan integrasi, dan berusaha untuk maksimalkan strategi pendanaan untuk penyedia likiditas. Platform mengadopsi secara otomatis strategi dua yang dieksekusi, menggunakan pemicu otomatis untuk komposisi, manajemen, alokasi, dan praktek lain.",
    jxsy:"Silet Benefits",
    jxsy1:"Memundang teman untuk menikmati keuntungan kaya yang dibawa oleh produk Bank China. biarkan pendapatan statis Anda, pendapatan tim, pendapatan kartu, dan kekayaan Anda berkembang di sini.",
    cxwf:"Permainan permainan inovatif",
    cxwf1:"No APP, no DAPP, no wallet authorization required; subvert traditional gameplay, open source contracts, and discard permissions; dual coin casting, both buying and selling rise, and passive asset appreciation; your ability determines the size of your wallet.",
    fxzl:"Total issuance amount",
    fxzl1:"RousePro hari ini membutuhkan konsensus sebelum memiliki koin. Keluaran monet: 0 dikeluarkan oleh pemegang konsensus, semua orang adalah pengeluar. Koncept: Melanggar konvensi tradisional, biarkan pasar menjadi pengeluar, menggunakan pasar lembu untuk mendukung bagian bawah, dan semua orang membuat uang. Mekanisme: Kasing koin ganda, deflasi ekstrim, naik spiral membuang semua otoritas, mekanisme secara otomatis dieksekusi, menunjukkan ketakutan dan keserakahan manusia, permainan pasar tanpa bubuk senjata. RousePro memperketat sirkulasi+kekuatan menghargai",
    rhcy:"Bagaimana untuk berpartisipasi",
    rhcy1: "Participation: Starting from 0.1BNB, transfer to the contract address and automatically trigger a gold standard of 1.5%/day profit. RousePro is twice as likely to be eliminated. RousePro can only sell, cannot buy, withdraw, pull, and destroy. If you cannot control everyone to sell, RousePro will rise.",
    about:"Anda dapat menghubungi kami melalui metode berikut!",
    net_error:"Net error",
      faq:"Q&A",
    rousev:"Informasi mengenai Rouse1.0",
  },
  pro: {
    "t1": "Komposisi Bunga Komplit Otomatis",
    "t2": "Platform Manajemen dan Distribusi",
    "t3": "Strategi Pengelolaan Dana Maksimal untuk Penyedia Likuiditas",
    "p1": "RousePro adalah protokol keuangan tanpa pengelola, tanpa kepercayaan, terdesentralisasi di rantai yang bekerja sama dengan protokol likuiditas PancakeSwap V3 untuk menyediakan layanan dan dukungan, serta kompatibel dan terintegrasi dengan banyak rantai blok dan Dex, serta mencari strategi pengelolaan dana maksimal untuk penyedia likuiditas.",
    "p2": "Platform menggunakan strategi ganda yang dieksekusi secara otomatis",
    "p3": "Menggunakan pemicu otomatis untuk praktek komposisi bunga, manajemen, distribusi, dan lainnya",
    "jx1": "Undang teman-teman untuk bersama-sama menikmati hasil yang luar biasa dari produk terdesentralisasi",
    "jx2": "Hasil Statis",
    "jx3": "Hasil Tim",
    "jx4": "Hasil Kartu",
    "jx5": "Kekayaan Anda terus berkembang di sini",
    "cx1": "Tanpa Aplikasi",
    "cx2": "Tanpa DAPP",
    "cx3": "Tanpa otorisasi dompet",
    "cx4": "Membalikkan cara bermain tradisional",
    "cx5": "Kontrak terbuka sumber",
    "cx6": "Hak akses dibuang",
    "cx7": "Pembuatan dua mata uang",
    "cx8": "Harga naik saat jual maupun beli",
    "cx9": "Nilai aset meningkat secara pasif",
    "cx10": "Kapasitas Anda",
    "cx11": "Menentukan ukuran dompet Anda",
    "fx1": "RousePro hari ini, konsensus dahulu sebelum ada mata uang",
    "fx2": "Volume penerbitan mata uang: 21,000,000",
    "fx3": "Sirkulasi dipublikasikan oleh konsensus, setiap orang bisa jadi penerbit mata uang",
    "fx4": "Konsep:",
    "fx5": "Memecahkan konvensi tradisional",
    "fx6": "Membuat pasar sebagai penerbit mata uang",
    "fx7": "Dukung dengan bull market",
    "fx8": "Mekanisme setiap orang dapat mendapatkan uang:",
    "fx9": "Pembuatan dua mata uang",
    "fx10": "Deflasi ekstrem",
    "fx11": "Spiral naik, semua hak akses dibuang",
    "fx12": "Mekanisme dieksekusi secara otomatis",
    "fx13": "Menunjukkan rasa takut dan keserakahan manusia",
    "fx14": "Permainan pasar tanpa perang",
    "fx15": "RousePro menyingkat sirkulasi + memaksa peningkatan nilai",
    "fx16": "Distribusi token Rouse",
    "fx17": "A: RousePro",
    "fx18": "Total penerbitan:",
    "fx19": "21,000,000",
    "fx20": "B: WuDao",
    "fx21": "Total penerbitan:",
    "fx22": "10,000,000",
    "fx23": "Produksi pembuatan, deflasi hingga: 1,000,000",
    "fx24": "Terbuka sumber",
    "fx25": "Buang hak akses",
    "fx26": "Kolam terkunci",
    "rh1": "Partisipasi: mulai dari 0.1 BNB, transfer ke alamat kontrak",
    "rh2": "Pemicu otomatis menghasilkan bunga berbasis emas 1.5%/hari",
    "rh3": "RousePro keluar dengan lipat 2",
    "rh4": "RousePro hanya bisa dijual tidak bisa dibeli",
    "rh5": "Saat menarik uang harga naik, saat harga naik uang habis",
    "rh6": "Jika Anda tidak bisa mengendalikan semua orang yang jual",
    "rh7": "RousePro akan naik"
  },
  "shop": {
    "menu": {
      "home": "Beranda",
      "category": "Produk",
      "faq": "Tanya Jawab",
      "me": "Saya",
    },

    "bar": {
      "sales": "Terlaris",
      "new": "Baru",
      "recommend": "Pilihan",
    },
    "info": {
      "recommend": "Rekomendasi",
      "nodata": "Tidak ada lebih banyak data",
      "loading": "Memuat...",
      "nogoods": "Tidak ada produk dalam kategori ini",
      "moreprops": "Lebih banyak produk dan atribut, klik untuk lihat",
      "detailtitle": "Deskripsi Produk",
      "like": "Produk Terkait",
      "allcate": "Kategori Produk",
      "allgoods": "Semua Produk"
    }
  },
  "faq": {
        "w_1": "Apa itu RousePro",
        "d_1": "RousePro adalah strategi dual-token yang dibangun di atas protokol keuangan berantai lengkap dengan mekanisme insentif penerbitan yang komprehensif. Ini menggabungkan Defi, kolam V3, teknologi token pintar, tanpa DAPP, tanpa APP, dan distribusi awal yang setara untuk semua orang, dengan RousePro dan WuDao tanpa penjualan pribadi, tanpa cadangan, tanpa node, kontrak sumber terbuka dan menyerahkan otoritas, dengan tujuan menciptakan komunitas otonom DAO yang sesungguhnya, meWuDaojudkan otonomi komunitas yang adil, transparan, dan demokratis.",
        "w_2": "Bagaimana cara berpartisipasi dalam komunitas otonom RousePro",
        "d_2": "Setiap dompet memulai dari setidaknya 0.1 $ BNB, dan batas investasi bulanan berikutnya akan meningkat sebesar 5 $ BNB.",
        "w_3": "Apa yang menonjol dari RousePro",
        "d_3": "Sederhana dan mudah dioperasikan, tidak perlu terhubung dengan DAPP membuat dompet lebih aman, mekanisme dual-token menjamin keadilan dan keberlanjutan insentif, ditambah dengan kontrak sumber terbuka, menyerahkan otoritas, bahkan tim teknis tidak dapat mengakses dana kolam.",
        "w_4": "Apa itu kontrak sumber terbuka",
        "d_4": "Kontrak cerdas sumber terbuka lebih transparan dan dapat diaudit, memberikan kepercayaan dan perlindungan kepada investor.",
        "w_5": "Bagaimana cara mendapatkan pendapatan di dalam RousePro",
      "d_5": "Setiap hari menghasilkan 1.5% pendapatan, setiap hari Minggu bisa menarik 7 hari pendapatan, memegang dua jenis koin pendapatan bulanan mulai dari 45%, jika alokasi koin yang dimiliki tepat, kenaikan harga koin akan memberi Anda tambahan pendapatan yang mengejutkan.",
      "w_6": "Bagaimana cara melihat penggandaan keluaran",
        "d_6": "Setelah terhubung dengan dompet di wudao.co, klik 'saya', dan ketika bagian penghasilan mencapai dua kali lipat dari investasi Anda, jumlah investasi dan pendapatan Anda akan menjadi 0, pada titik ini Anda dapat memilih untuk melakukan reinvestasi.",
        "w_7": "Apakah kinerja tim saya akan tetap ada setelah penggandaan keluaran",
        "d_7": "Ya, kinerja tim Anda akan tetap ada dan akan dibayarkan bersama dengan investasi Anda saat Anda melakukan reinvestasi.",
        "w_8": "Bagaimana cara menarik token RousePro",
        "d_8": "Kirimkan RousePro sebesar 0,0001 ke alamat kontrak RousePro untuk menarik token RousePro Anda.",
        "w_9": "Bagaimana cara menarik token WuDao",
        "d_9": "Kirimkan RousePro sebesar 0,0002 ke alamat kontrak RousePro untuk menarik token WuDao Anda.",
        "w_10": "Berapa sering saya bisa menarik RousePro",
      "d_10": "RousePro adalah setiap 7 hari sekali pengambilan, waktu pengambilan mulai dari setiap hari Minggu 00:00 sampai 23:59 bisa pengambilan sekali, jika tidak sampai pada waktu tertentu tidak bisa dikirim, bahkan jika dikirim nanti juga tidak akan mendapatkan balasan.",
      "w_11": "Mengapa kontrak menunjukkan kesalahan saat menarik RousePro",
      "d_11": "Karena tidak dalam rentang waktu pengambilan koin, atau biaya bahan bakar sendiri tidak cukup.",
      "w_12": "Mengapa dompet kadang-kadang tidak menampilkan setoran atau penarikan",
        "d_12": "Daerah bermasalah mungkin disebabkan oleh jaringan di daratan, disarankan untuk menggunakan akselerator.",
        "w_13": "Setelah mengirimkan 0.00001, catatan transaksi RousePro tidak ditampilkan",
        "d_13": "Ini adalah masalah pengaturan dan tampilan dom pet, disarankan untuk memeriksanya di browser blockchain Binance.",
        "w_14": "Bagaimana cara menukarkan token RousePro menjadi token WuDao",
        "d_14": "Pertama-tama, periksa jumlah token WuDao yang dapat ditukarkan di situs web resmi, kemudian kirimkan jumlah yang ingin ditukarkan dari RousePro ke alamat kontrak RousePro.",
        "w_15": "Bagaimana cara menukarkan token WuDao menjadi token RousePro",
        "d_15": "Kirimkan token WuDao yang ingin ditukarkan dari dompet WuDao ke alamat kontrak WuDao.",
        "w_16": "Mengapa jumlah token WuDao yang dapat ditarik di dompet resmi tidak sesuai dengan jumlah yang diterima",
        "d_16": "Misalnya, jika jumlah token WuDao yang dapat ditarik adalah 1000, maka jumlah token yang diterima adalah 20 WuDao, dihitung dengan rumus: 1000 x 2,5% x 80%.",
        "w_17": "Bagaimana cara memeriksa harga RousePro",
        "d_17": "Anda dapat klik di sini untuk memeriksanya ",
        "w_18": "Bagaimana cara menukarkan RousePro ke BNB/USDT",
        "d_18": "Anda dapat mengimpor token RousePro ke kontrak RousePro di pancakeswap.finance, sesuaikan slippage ke 12% kemudian tukarkan langsung ke BNB/USDT.",
        "w_19": "Bagaimana cara membuka situs web resmi",
        "d_19": "Pilih 'Discover' di dalam dompet, masukkan alamat situs web resmi: www.wudao.co, dan Anda dapat membuka situs web resmi serta memeriksa data akun dan kinerja tim Anda.",
        "w_20": "Mengapa data tidak muncul di situs web resmi setelah setoran BNB",
        "d_20": "Pengguna baru perlu menarik token RousePro setelah pertama kali melakukan setoran BNB agar situs web mulai menghitung dan menampilkan data yang relevan.",
    "w_21": "Apakah waktu penarikan dana setiap hari tetap atau tidak?",
    "d_21": "Waktu penarikan ROUSEPRO Coin dihitung mulai dari waktu penarikan pertama Anda, dan setelah 24 jam 5 menit, Anda dapat melakukan penarikan dana untuk hari kedua.",
    // 2.0
      "w_22": "Mengapa jumlah RousePro koin setiap minggu berbeda",
      "d_22": "Pendapatan setiap minggu didistribusikan berdasarkan harga USDT saat Anda berinvestasi BNB, berdasarkan waktu Anda menarik RousePro koin terhadap harga RousePro koin untuk menghitung jumlah RousePro koin yang Anda peroleh, ketika harga RousePro koin tinggi maka jumlah RousePro koin yang diberikan sedikit, ketika harga RousePro koin rendah maka jumlah RousePro koin yang diberikan banyak.",
      "w_23": "Bagaimana mekanisme kenaikan dan penurunan harga RousePro dihitung",
        "d_23": "Ketika pengguna menarik token RousePro, harga RousePro akan naik; ketika pengguna menukarkan RousePro ke BNB/USDT, harga RousePro akan turun. Selain itu, kenaikan dan penurunan harga BNB juga akan mempengaruhi kenaikan dan penurunan harga RousePro.",
        "w_24": "Bagaimana mekanisme kenaikan dan penurunan harga WuDao dihitung",
        "d_24": "WuDao tidak memiliki harga yang sebenarnya, tetapi hanya mengacu pada tingkat pertukaran dengan RousePro. Dengan kata lain, semakin sering Anda menarik atau menjual WuDao, semakin tinggi tingkat pertukarannya. Setiap kali menukarkan WuDao ke RousePro, tingkat pertukaran WuDao akan meningkat, karena rumus WuDao adalah: jumlah di dalam kolam cetak RousePro ÷ jumlah yang beredar dari WuDao.",
        "w_25": "Dengan menghitung pendapatan 1,5%, penggandaan keluaran adalah 133 hari, mengapa dikatakan 66 hari",
        "d_25": "Dengan menghitung pendapatan 1,5%, akun yang murni statis, kontrak akan mendeteksi penggandaan keluaran setelah 133 hari. Namun, RousePro menghasilkan pendapatan melalui dua token, RousePro dan WuDao, sehingga setelah mencapai periode tertentu, pendapatan aktual Anda akan melampaui dua kali lipat atau bahkan lebih dari itu dalam 66 hari.",
        "w_26": "Apa itu komunitas kripto",
        "d_26": "Sebuah kelompok orang yang terlibat dalam proyek kripto atau memiliki kripto.",
        "w_27": "Apa itu kunci publik",
        "d_27": "Kunci publik adalah alamat dompet kripto. Dengan alamat ini, kripto hanya dapat dikirim kepada orang tersebut. Tidak ada orang lain yang dapat menarik atau mengakses akunnya.",
        "w_28": "Apa itu AMA ",
        "d_28": "Tanya Saya Apa Saja, biasanya merujuk kepada sesi tanya jawab yang diadakan oleh tim proyek atau orang yang bertanggung jawab atas perdagangan.",
        "w_29": "Apa itu ATH",
        "d_29": "Singkatan dari 'all-time high' atau puncak tertinggi dalam sejarah, yang menunjukkan titik harga tertinggi yang pernah dicapai oleh suatu token kripto.",
        "w_30": "Apa itu kripto shitcoin",
        "d_30": "Awalnya digunakan untuk merujuk pada kripto selain Bitcoin, sekarang mungkin merujuk pada kripto baru dengan kapitalisasi pasar yang relatif kecil.",
        "w_31": "Apa itu Apeing In",
        "d_31": "Membeli proyek NFT tanpa melakukan penelitian terlebih dahulu.",
        "w_32": "Apa itu pembakaran token",
        "d_32": "Penghapusan NFT. Misalnya, jika koleksi yang seharusnya terdiri dari 10.000 token NFT hanya terjual 5.000 token, tim pengembang mungkin memutuskan untuk 'membakar' 5.000 token NFT yang tersisa.",
        "w_33": "Apa itu membeli ujung",
        "d_33": "Membeli aset kripto setelah tren atau peningkatan yang signifikan dalam harga.",
        "w_34": "Apa itu cincin perak",
        "d_34": "Mengelilingi sejumlah besar pesanan yang relatif kecil di sekitar harga pasar yang ditawarkan, menciptakan 'dinding' yang sulit ditembus untuk pembeli atau penjual lain.",
        "w_35": "Apa itu aplikasi terdesentralisasi",
        "d_35": "Aplikasi terdesentralisasi (dApp) adalah aplikasi yang dijalankan di blockchain dan tidak tergantung pada satu server pusat. Mereka sering kali memiliki token kripto dan menggunakan kontrak cerdas.",
        "w_36": "Apa itu jaringan blockchain",
        "d_36": "Jaringan blockchain adalah jaringan peer-to-peer yang terdiri dari banyak node (komputer individual) yang digunakan untuk memverifikasi dan merekam transaksi.",
        "w_37": "Apa itu volume perdagangan",
        "d_37": "Volume perdagangan mengacu pada jumlah unit aset kripto yang diperdagangkan dalam periode waktu tertentu.",
        "w_38": "Apa itu leverage",
        "d_38": "Leverage memungkinkan trader untuk mengendalikan jumlah aset yang lebih besar daripada yang mereka miliki, dengan menggunakan pinjaman. Ini meningkatkan potensi keuntungan, tetapi juga meningkatkan risiko kerugian.",
        "w_39": "Apa itu DeFi",
        "d_39": "DeFi adalah singkatan dari 'keuangan terdesentralisasi', yang mengacu pada aplikasi keuangan yang beroperasi di blockchain dan tidak bergantung pada lembaga keuangan tradisional seperti bank.",
        "w_40": "Apa itu NFT",
        "d_40": "NFT adalah singkatan dari 'token non-fungible', yang merupakan jenis kripto yang unik dan tidak dapat dipertukarkan dengan cara yang sama dengan kripto lain yang setara.",
        "w_41": "Apa itu token swap",
        "d_41": "Pertukaran token adalah proses pertukaran satu jenis token kripto dengan jenis lainnya. Ini sering kali terjadi melalui platform atau bursa kripto.",
        "w_42": "Apa itu memegang kuat",
        "d_42": "Tidak menjual aset kripto meskipun harga turun, karena yakin akan kenaikan harga di masa depan.",
        "w_43": "Apa itu pemegang dompet",
        "d_43": "Pemegang dompet adalah seseorang yang memiliki dompet kripto, yang merupakan tempat mereka menyimpan dan mengelola aset kripto mereka.",
        "w_44": "Apa itu pertambangan",
        "d_44": "Pertambangan adalah proses verifikasi transaksi dan menambahkannya ke blockchain. Ini dilakukan oleh 'penambang' yang menggunakan kekuatan komputasi untuk memecahkan teka-teki matematika.",
        "w_45": "Apa itu serangan 51%",
        "d_45": "Serangan 51% terjadi ketika satu entitas atau kelompok mengontrol lebih dari setengah daya komputasi jaringan blockchain, memberi mereka kekuatan untuk memanipulasi transaksi atau mencegah konfirmasi transaksi lainnya.",
        "w_46": "Apa itu dompet panas",
        "d_46": "Dompet panas adalah dompet kripto yang terhubung ke internet, sering digunakan untuk menyimpan jumlah kripto yang akan digunakan untuk transaksi sehari-hari.",
        "w_47": "Apa itu dompet dingin",
        "d_47": "Dompet dingin adalah dompet kripto yang tidak terhubung ke internet, sering digunakan untuk menyimpan kripto dalam jumlah besar untuk jangka waktu yang lama.",
        "w_48": "Apa itu alamat dompet",
        "d_48": "Alamat dompet adalah alamat unik yang digunakan untuk menerima aset kripto. Ini mirip dengan nomor rekening bank.",
        "w_49": "Apa itu kripto altcoin",
        "d_49": "Altcoin adalah semua kripto selain Bitcoin.",
        "w_50": "Apa itu ICO",
        "d_50": "ICO adalah singkatan dari 'penawaran koin awal', yang merupakan cara bagi proyek kripto untuk mengumpulkan dana dengan menjual token mereka kepada investor.",
        "w_51": "Apa itu ROI",
        "d_51": "ROI adalah singkatan dari 'return on investment', yang mengukur seberapa menguntungkannya sebuah investasi dibandingkan dengan biayanya.",
        "w_52": "Apa itu token ERC-20",
        "d_52": "Token ERC-20 adalah token kripto yang dihasilkan di blockchain Ethereum dan mengikuti standar ERC-20, yang membuatnya dapat diperdagangkan dengan token ERC-20 lainnya dan dapat disimpan di dompet yang mendukung standar tersebut.",
        "w_53": "Apa itu gas",
        "d_53": "Gas adalah biaya yang dibayarkan kepada penambang blockchain untuk memproses transaksi atau kontrak cerdas. Semakin tinggi gas yang dibayarkan, semakin cepat transaksi akan diproses.",
        "w_54": "Apa itu hard fork",
        "d_54": "Hard fork adalah perubahan permanen dalam protokol blockchain yang membuat blok sebelumnya atau transaksi tidak valid. Ini dapat terjadi ketika ada perselisihan dalam komunitas tentang bagaimana blockchain harus beroperasi.",
        "w_55": "Apa itu soft fork",
        "d_55": "Soft fork adalah perubahan dalam protokol blockchain yang membuat blok sebelumnya atau transaksi valid. Ini umumnya dianggap sebagai pemutusan yang lebih sedikit dalam jaringan daripada hard fork.",
        "w_56": "Apa itu kecerdasan buatan",
        "d_56": "Kecerdasan buatan adalah bidang ilmu komputer yang bertujuan untuk menciptakan mesin atau program komputer yang dapat belajar dan berpikir seperti manusia.",
        "w_57": "Apa itu mesin pembelajaran",
        "d_57": "Mesin pembelajaran adalah cabang dari kecerdasan buatan yang fokus pada pengembangan teknik yang memungkinkan komputer untuk belajar dari data dan meningkatkan kinerjanya seiring waktu tanpa perlu diprogram ulang.",
        "w_58": "Apa itu enkripsi",
        "d_58": "Enkripsi adalah proses mengubah informasi agar tidak dapat dimengerti kecuali oleh orang yang memiliki 'kunci' yang diperlukan untuk mengembalikannya ke bentuk semula.",
        "w_59": "Apa itu phishing",
        "d_59": "Phishing adalah praktik penipuan di mana seseorang mencoba untuk mendapatkan informasi sensitif seperti kata sandi atau rincian kartu kredit dengan menyamar sebagai entitas tepercaya dalam komunikasi elektronik.",
        "w_60": "Apa itu DDoS",
        "d_60": "DDoS adalah serangan yang dilakukan terhadap server atau jaringan dengan membanjiri mereka dengan lalu lintas internet sehingga mereka tidak dapat berfungsi dengan benar.",
        "w_61": "Apa itu algoritme konsensus",
        "d_61": "Algoritme konsensus adalah aturan yang digunakan oleh jaringan blockchain untuk mencapai kesepakatan tentang keadaan jaringan, seperti transaksi yang valid dan urutan blok.",
    }
}