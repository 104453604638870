export default {
  m: {
    connect:'kết nối ví',
    wallet:'Vui lòng cài đặt ví metamask',
    no_connect:'Ví không kết nối',
    pledgeSuccess: "Cam kết thành công",
    share_copy:"Đã sao chép",
    bind_success: "Binding thành công",
    address_error: "Lỗi địa chỉ",
    home:"Trang đầu",
    mining:"khai thác mỏ",
    me:"Của tôi",
    lan:"Ngôn ngữ",
    invite_num:"Số người có giá trị",
    invest:"đầu tư",
    profit:'lợi nhuận',
    release:"để được giải phóng",
    cash:'có thể rút ra',
    price:"giá cả",
    team_invest:"cam kết nhóm",
    pool:"Bể đúc",
    circulation:"lượng lưu thông",
    small_card:"thẻ tiên phong",
    big_card:"thẻ sáng tạo",
    xq:"Dự án",
    account:"Tài khoản của tôi",
    max:"WuDao có thể đúc tối đa",
    nft: "Thẻ của tôi",
    myinvite: "Thư mời của tôi",
    tjz: "Người giới thiệu",
    ztuser: "đẩy thẳng người dùng",
    view: "xem",
    nouser: "Chưa có người dùng nào được đề xuất",
    copy: "Đã sao chép",
    ptjs: "Giới thiệu nền tảng",
    ptjs2: "RousePro là một giao thức tài chính phi tập trung, không cần tin cậy, phi tập trung trên chuỗi cung cấp dịch vụ và hỗ trợ hợp tác với giao thức thanh khoản PancakeSwap V3, cũng như nhiều blockchain và Dex tương thích và tích hợp và tìm kiếm chiến lược tài trợ tối đa cho các nhà cung cấp thanh khoản. Nền tảng sử dụng chiến lược song song tự động thực hiện, sử dụng kích hoạt tự động để thực hành lãi kép. Quản lý, phân phối, v.v.",
    jxsy: "Tĩnh hưởng lợi nhuận",
    jxsy1: "Mời bạn bè của bạn để thưởng thức các sản phẩm đi đến ngân hàng trung ương mang lại lợi nhuận phong phú. Cho phép bạn thu nhập tĩnh, thu nhập nhóm, thu nhập thẻ, sự giàu có của bạn phát triển ở đây",
    cxwf: "Cách chơi sáng tạo",
    cxwf1: "Không APP, không DApps, không cần trao quyền ví; phá vỡ cách chơi truyền thống, hợp đồng nguồn mở, quyền hạn vứt bỏ; đúc tiền đôi, mua bán đều tăng, tài sản bị động tăng giá trị; kích thước năng lực của bạn, quyết định kích thước ví của bạn",
    fxzl: "Tổng lượng phát hành",
    fxzl1: "RousePro của ngày hôm nay, là trước tiên phải có sự đồng thuận trong việc có tiền xu. Lượng phát hành tiền xu: 0 lưu thông được phát hành bởi người đồng thuận, mọi người đều phát hành ý tưởng của bên phát hành: phá vỡ thói quen truyền thống, để thị trường là bên phát hành tiền xu, sử dụng đáy bò, mọi người kiếm tiền cơ chế: đúc tiền đôi, giảm phát cực đoan, xoắn ốc tăng loại bỏ tất cả các quyền hạn, cơ chế tự động thực hiện, thể hiện sự sợ hãi và tham lam của con người, một trò chơi thị trường không có khói thuốc súng RousePro thắt chặt lưu thông+ép buộc đúc tăng giá trị",
    rhcy: "Làm thế nào để tham gia",
    rhcy1: "Tham gia: 0.1BNB bắt đầu chuyển sang địa chỉ hợp đồng, tự động kích hoạt bản vị vàng 1,5%/ngày RousePro 2x out RousePro Chỉ có thể bán, không thể mua, rút tiền, kéo và phá hủy Nếu bạn không thể kiểm soát tất cả mọi người bán, RousePro sẽ tăng.",
    about: "Bạn có thể liên lạc với chúng tôi theo cách dưới đây!",
    net_error:"Lỗi mạng",
    faq:"Bách Khoa Hỏi & Đáp",
    rousev:"Tìm kiếm Rouse1.0",
  },
  pro: {
    "t1": "Lãi kép tự động thực thi",
    "t2": "Nền tảng quản lý, phân phối",
    "t3": "Tìm kiếm chiến lược tài chính tối ưu hóa cho nhà cung cấp tính lỏng",
    "p1": "RousePro là một giao thức tài chính phi quản lý, không cần niềm tin, phi tập trung trên chuỗi hợp tác với giao thức tính lỏng PancakeSwap V3 để cung cấp dịch vụ và hỗ trợ, cũng như tương thích và tích hợp với nhiều chuỗi khối và Dex, đồng thời tìm kiếm chiến lược tài chính tối ưu hóa cho nhà cung cấp tính lỏng.",
    "p2": "Nền tảng áp dụng chiến lược kép tự động thực thi",
    "p3": "Sử dụng kích hoạt tự động để thực hiện lãi kép, quản lý, phân phối v.v.",
    "jx1": "Mời bạn bè cùng hưởng thụ lợi nhuận hậu hĩnh từ sản phẩm phi tập trung",
    "jx2": "Lợi nhuận tĩnh",
    "jx3": "Lợi nhuận nhóm",
    "jx4": "Lợi nhuận thẻ",
    "jx5": "Tài sản của bạn sẽ tăng trưởng không ngừng tại đây",
    "cx1": "Không có ứng dụng",
    "cx2": "Không có DAPP",
    "cx3": "Không cần ủy quyền ví",
    "cx4": "Lật ngược cách chơi truyền thống",
    "cx5": "Hợp đồng nguồn mở",
    "cx6": "Từ bỏ quyền",
    "cx7": "Đúc đồng tiền kép",
    "cx8": "Cả mua lẫn bán đều tăng giá",
    "cx9": "Tăng trưởng giá trị tài sản một cách thụ động",
    "cx10": "Năng lực của bạn",
    "cx11": "Quyết định kích thước ví của bạn",
    "fx1": "RousePro hôm nay, cần có đồng thuận trước mới có tiền",
    "fx2": "Lượng phát hành tiền: 21,000,000",
    "fx3": "Lưu thông được phát hành bởi những người đồng thuận, mỗi người đều là nhà phát hành",
    "fx4": "Lý niệm:",
    "fx5": "Phá vỡ quy tắc truyền thống",
    "fx6": "Làm cho thị trường trở thành nhà phát hành",
    "fx7": "Dùng thị trường tăng trưởng để giữ giá",
    "fx8": "Cơ chế kiếm tiền của mọi người:",
    "fx9": "Đúc đồng tiền kép",
    "fx10": "Thu hẹp cực kỳ",
    "fx11": "Tăng trưởng xoắn ốc, từ bỏ tất cả quyền",
    "fx12": "Cơ chế tự động thực thi",
    "fx13": "Hiện thị nỗi sợ hãi và tham lam của con người",
    "fx14": "Một cuộc cạnh tranh thị trường không có khói nổ",
    "fx15": "RousePro thu hẹp lưu thông + ép giá tăng giá trị",
    "fx16": "Phân chia Rouse Token",
    "fx17": "A: RousePro",
    "fx18": "Tổng lượng phát hành:",
    "fx19": "21,000,000",
    "fx20": "B: WuDao",
    "fx21": "Tổng lượng phát hành:",
    "fx22": "10,000,000",
    "fx23": "Đúc sản xuất, giảm lượng cung đến: 1,000,000",
    "fx24": "Mã nguồn mở",
    "fx25": "Từ bỏ quyền",
    "fx26": "Khóa hồ sơ",
    "rh1": "Tham gia: bắt đầu từ 0.1BNB, chuyển vào địa chỉ hợp đồng",
    "rh2": "Kích hoạt tự động thu lãi 1.5%/ngày theo cơ chế vàng bản vị",
    "rh3": "RousePro thoát với 2 lần lợi nhuận",
    "rh4": "RousePro chỉ có thể bán không thể mua",
    "rh5": "Khi rút tiền thì giá tăng, khi giá tăng thì tiêu huỷ",
    "rh6": "Nếu bạn không thể kiểm soát tất cả mọi người bán",
    "rh7": "RousePro sẽ tăng giá",
  },
  "shop": {
    "menu": {
      "home": "Trang chủ",
      "category": "Sản phẩm",
      "faq": "Q&A",
      "me": "Tôi",
    },

    "bar": {
      "sales": "Bán chạy",
      "new": "Mới ra",
      "recommend": "Chọn lọc",
    },
    "info": {
      "recommend": "Đề xuất",
      "nodata": "Không có dữ liệu nữa",
      "loading": "Đang tải",
      "nogoods": "Không có sản phẩm trong danh mục này",
      "moreprops": "Xem thêm sản phẩm và thuộc tính",
      "detailtitle": "Giới thiệu sản phẩm",
      "like": "Sản phẩm liên quan",
      "allcate": "Phân loại sản phẩm",
      "allgoods": "Tất cả sản phẩm"
    }
  },
  faq:{
    w_1:'RousePro là gì',
    d_1:'RousePro là một chiến lược song phương được xây dựng trên một giao thức tài chính toàn chuỗi, có cơ chế thưởng phát hành hoàn chỉnh, kết hợp DeFi, hồ bơi V3, công nghệ thông minh token, không cần DAPP không APP, và mọi người được phân phối bình đẳng trước khi phát hành, RousePro và WuDao không có nguồn vốn riêng tư, không có dự trữ, không có nút, hợp đồng mã nguồn mở và từ bỏ quyền lực, mục tiêu là tạo ra một cộng đồng tự trị DAO thực sự, thực hiện cộng đồng tự trị bình đẳng, công bằng, minh bạch và dân chủ.',
    w_2:"Làm thế nào để tham gia cộng đồng tự trị RousePro",
    d_2:"Mỗi ví tiền ít nhất từ 0.1 $BNB bắt đầu, sau đó mỗi tháng giới hạn đầu tư tăng thêm 5 $BNB",
    w_3:"Điểm nổi bật của RousePro là gì",
    d_3:"Đơn giản và dễ hiểu về phương diện hoạt động, không cần liên kết DAPP để làm cho ví tiền an toàn hơn, cơ chế kép giải quyết công bằng và bền vững của cơ chế thưởng, kết hợp với hợp đồng mã nguồn mở, từ bỏ quyền lực, kỹ sư cũng không thể sử dụng được hồ bơi tiền.",
    w_4:"Hợp đồng mã nguồn mở là gì",
    d_4:"Hợp đồng thông minh mã nguồn mở là một cách mạnh mẽ hơn về tính minh bạch và có thể kiểm tra, có thể cung cấp niềm tin và bảo vệ cho nhà đầu tư.",
    w_5:"Làm thế nào để kiếm lợi nhuận trong RousePro",
    "d_5": "Mỗi ngày tạo ra 1.5% lợi nhuận, có thể nhận 7 ngày lợi nhuận vào mỗi Chủ nhật, nếu giữ hai loại tiền điện tử, lợi nhuận hàng tháng bắt đầu từ 45%. Nếu phân bổ tiền điện tử thích hợp, giá cả tăng của tiền điện tử sẽ mang lại lợi nhuận bổ sung bất ngờ cho bạn.",
    w_6:"Làm thế nào để xem cơ chế kép",
    d_6:"Sau khi liên kết ví tiền với wudao.co, nhấp vào “của tôi”, bạn có thể xem trên trang web rằng một khi phần lợi nhuận đạt được hai lần số tiền đầu tư, số tiền đầu tư và lợi nhuận của bạn sẽ trở thành 0, lúc này bạn có thể chọn lựa tái đầu tư.",
    w_7:"Sau khi chấp nhận cơ chế kép, kết quả kinh doanh của tôi có được giữ lại không",
    d_7:"Có, kết quả kinh doanh của nhóm của bạn sẽ được giữ lại và được chi trả kèm với việc tái đầu tư của bạn.",
    w_8:"Làm thế nào để rút tiền từ RousePro",
    d_8:"Chuyển RousePro 0,0001 đến địa chỉ hợp đồng RousePro để rút tiền RousePro của bạn.",
    w_9:"Làm thế nào để rút tiền từ WuDao",
    d_9:"Chuyển RousePro 0,0002 đến địa chỉ hợp đồng RousePro để rút tiền WuDao của bạn.",
    w_10:"RousePro trích xuất mỗi bao lâu một lần",
    "d_10": "RousePro là lấy mỗi 7 ngày một lần, thời gian lấy là từ 00:00 đến 23:59 mỗi Chủ nhật. Nếu không đạt đến thời gian thì không thể gửi, ngay cả khi gửi đi rồi cũng không nhận được trả lời.",
    w_11:"Tại sao khi rút RousePro hợp đồng hiển thị lỗi",
    "d_11": "Đó là vì không trong khoảng thời gian có thể nhận tiền điện tử, hoặc phí gas của bạn không đủ.",
    w_12:"Tại sao đôi khi sau khi rút hoặc đầu tư, ví tiền không hiển thị",
    d_12:"Khu vực lục địa có thể gây ra vấn đề mạng, khuyến nghị sử dụng bộ tăng tốc.",
    w_13:"Sau khi gửi 0,00001, giao dịch RousePro không hiển thị",
    d_13:"Đây là vấn đề về cài đặt ví tiền và hiển thị, khuyến nghị tra cứu trên trình duyệt chuỗi khối Binance.",
    w_14:"Làm thế nào để đúc tiền RousePro thành tiền WuDao",
    d_14:"Đầu tiên, bạn cần kiểm trasố lượng WuDao có thể đúc bằng cách truy cập trang web chính thức, sau đó chuyển số lượng cần đúc từ RousePro đến địa chỉ hợp đồng RousePro.",
    w_15:"Làm thế nào để đổi tiền WuDao thành tiền RousePro",
    d_15:"Chuyển số lượng cần đổi từ WuDao đến địa chỉ hợp đồng WuDao của WuDao.",
    w_16:"Tại sao số tiền WuDao có thể rút được trên trang web chính thức không giống với số tiền nhận được thực tế",
    d_16:"Ví dụ, số tiền WuDao có thể rút được là 1000, công thức là 1000 X 2.5% X 80% = 20 WuDao.",
    w_17:"Làm thế nào để kiểm tra giá RousePro",
    d_17:"Bạn có thể nhấp vào đây để truy cập.",
    w_18:"Làm thế nào để đổi tiền RousePro sang BNB/USDT",
    d_18:"Bạn có thể truy cập pancakeswap.finance để đổi tiền RousePro thành BNB/USDT sau khi nhập hợp đồng RousePro, điều chỉnh trượt 12% sau đó đổi ngay sang BNB/USDT.",
    w_19:"Làm thế nào để mở trang web chính thức",
    d_19:"Chọn khám phá trong ví tiền, nhập địa chỉ trang web chính thức: www.wudao.co, bạn có thể mở trang web chính thức và tra cứu dữ liệu tài khoản và kết quả kinh doanh nhóm của mình.",
    w_20:"Tại sao sau khi đầu tư BNB, trang web chính thức không hiển thị dữ liệu",
    d_20:"Người dùng mới sau khi đầu tư BNB lần đầu tiên cần rút tiền RousePro một lần, sau đó trang web chính thức mới bắt đầu tính toán và hiển thị các dữ liệu.",
    "w_21": "Thời gian rút tiền hàng ngày có cố định không",
    "d_21": "Thời gian rút tiền của ROUSEPRO Coin được tính từ thời gian rút tiền lần đầu tiên của bạn, sau 24 giờ 5 phút, bạn có thể tiến hành rút tiền vào ngày hôm sau.",
    // 2.0
    "w_22": "Tại sao số lượng RousePro nhận mỗi tuần khác nhau",
    "d_22": "Lợi nhuận hàng tuần được phân bổ dựa trên giá USDT khi bạn đầu tư BNB, tính toán số lượng RousePro bạn nhận được dựa trên thời gian bạn nhận RousePro và giá RousePro. Khi giá RousePro cao thì số lượng RousePro ít, khi giá RousePro thấp thì số lượng RousePro nhiều.",
    w_23:"Cơ chế tăng và giảm giá của RousePro là như thế nào",
    d_23:"Khi người dùng rút tiền RousePro, giá RousePro sẽ tăng lên, khi người dùng đổi tiền RousePro sang BNB/USDT, giá RousePro sẽ giảm xuống. Đồng thời, sự tăng và giảm giá của BNB cũng sẽ ảnh hưởng đến sự tăng và giảm giá của RousePro",
    w_24:"Cơ chế tăng và giảm giá của WuDao là như thế nào",
    d_24:"WuDao không có giá thực tế, nhưng nó chỉ tương ứng với tỷ giá đổi tiền RousePro, đơn giản là mỗi lần nhận hoặc bán, tỷ lệ đổi WuDao sang RousePro sẽ tiếp tục tăng, mỗi lần đổi từ WuDao sang RousePro cũng sẽ tăng, vì công thức tính WuDao là: Số tiền trong hồ bơi RousePro ÷ số tiền lưu thông của WuDao.",
    w_25:"Dựa trên việc tính 1,5% lợi nhuận, vậy tại sao nói là 66 ngày mà không phải 133 ngày",
    d_25:"Dựa trên việc tính 1,5% lợi nhuận hàng ngày, chỉ cần hai lần phân phối lợi nhuận là 100%, vì vậy là 66 ngày, nhưng mỗi lần phân phối chỉ phân phối 1,5% số lượng chưa được phân phối, nên cần hai lần phân phối là 100%, là 133 ngày.",
  w_26:"Cơ chế thưởng là gì",
  d_26:"Nó không có tác động lên hồ bơi RousePro, mỗi người nhận 1,5% lợi nhuận hàng ngày trong hồ bơi, lợi nhuận hàng tháng từ 45% trở lên, với hai loại tiền.",
  w_27:"Cơ chế đầu tư là gì",
  d_27:"Hỗ trợ hai loại tiền, RousePro và WuDao, mỗi ví tiền ít nhất 1 $BNB bắt đầu, sau đó mỗi tháng giới hạn đầu tư tăng thêm 5 $BNB, sau khi liên kết ví tiền, mỗi ngày nhận 1,5% lợi nhuận, lợi nhuận hàng tháng từ 45% trở lên, với hai loại tiền, nếu phân phối tiền giữa các loại tiền một cách hợp lý, sự tăng giá của tiền sẽ mang lại cho bạn lợi nhuận bổ sung đầy bất ngờ.",
  w_28:"WuDao có mua không",
  d_28:"Không thể mua WuDao, chỉ có thể nhận WuDao miễn phí thông qua cơ chế thưởng hàng ngày, không cần đầu tư vào WuDao, và không có quỹ lưu thông WuDao.",
  w_29:"Ví tiền của bạn cần được đảm bảo rằng không còn tiền trong hồ bơi mới có thể rút tiền RousePro và WuDao",
  d_29:"Chỉ có thể rút tiền RousePro và WuDao trong trường hợp không có tiền trong hồ bơi mới có thể rút, sau khi rút hết tiền có thể rút tiếp.",
  w_30:"Tại sao phần lợi nhuận của tôi sau khi rút tiền RousePro không tăng",
  d_30:"Nếu tỷ lệ WuDao tăng lên, RousePro có thể bị rút bớt để duy trì tỷ lệ WuDao, trong trường hợp này, lợi nhuận tiếp theo sẽ giảm đi.",
  w_31:"Có phải RousePro và WuDao đều có giới hạn tăng thêm không",
  d_31:"Có, mỗi tháng giới hạn đầu tư tăng thêm 5 $BNB, nhưng không giới hạn về thời gian đầu tư.",
  w_32:"WuDao có tác động đến giá RousePro không",
  d_32:"Có, mỗi lần nhận hoặc đổi từ RousePro sang WuDao, giá RousePro sẽ tăng, mỗi lần rút tiền hoặc đổi từ WuDao sang RousePro, giá RousePro sẽ giảm.",
  w_33:"WuDao và RousePro có phải là quỹ không",
  d_33:"Không, RousePro và WuDao là dạng tiền mã hóa kỹ thuật số.",
  w_34:"Lợi nhuận hàng tháng từ cơ chế thưởng của RousePro thay đổi như thế nào",
  d_34:"Nó sẽ tăng khi tỷ lệ RousePro tăng lên, và giảm khi tỷ lệ RousePro giảm.",
  w_35:"Sau khi rút tiền, cần bao lâu mới có thể rút tiền tiếp theo",
  d_35:"Sau khi rút tiền RousePro, bạn có thể rút tiếp RousePro vào ngày tiếp theo, sau khi rút tiền WuDao, bạn có thể rút tiếp WuDao một lần.",
  w_36:"Lợi nhuận hàng tháng là bao nhiêu",
  d_36:"Lợi nhuận hàng tháng từ 45% trở lên với hai loại tiền, nếu phân phối tiền giữa các loại tiền một cách hợp lý, sự tăng giá của tiền sẽ mang lại cho bạn lợi nhuận bổ sung đầy bất ngờ.",
  w_37:"Có thể liên kết nhiều ví tiền với cùng một tài khoản WuDao không",
  d_37:"Có, nhưng vui lòng chú ý rằng số lượng ví tiền và số lượng tài khoản được liên kết không giới hạn, nhưng tài khoản chỉ được liên kết với một ví tiền duy nhất.",
  w_38:"Nếu tôi rút tiền mà hồ bơi không đủ, sao đó tiền rút sẽ đi đâu",
  d_38:"Sẽ vào hồ bơi WuDao của cộng đồng hoặc vào hồ bơi RousePro.",
  w_39:"Có thể rút tiền trong thời gian không hoạt động không",
  d_39:"Có thể, không giới hạn thời gian rút tiền, không cần phải là thời gian hoạt động.",
  w_40:"Có thể tham gia RousePro một cách an toàn không",
  d_40:"Có, RousePro không cần liên kết DAPP không cần APP, hình thức liên kết thông qua giao diện trang web chính thức để làm cho ví tiền an toàn hơn.",
  w_41:"Có thể nhận WuDao một cách an toàn không",
  d_41:"Có, WuDao không có giá trị, không thể mua hoặc bán, chỉ tương ứng với tỷ lệ đổi tiền RousePro, không có quỹ lưu thông WuDao, không cần đầu tư vào WuDao.",
  w_42:"Làm thế nào để kiểm tra số lượng WuDao có thể rút được",
  d_42:"Nhấp vào RousePro trên trang web chính thức để kiểm tra.",
  w_43:"Làm thế nào để kiểm tra số lượng RousePro đã rút",
  d_43:"Nhấp vào RousePro trên trang web chính thức để kiểm tra.",
  w_44:"Có thể tìm thấy gì trên trang web chính thức",
  d_44:"Có thể tra cứu dữ liệu tài khoản và kết quả kinh doanh nhóm của bạn, và tìm hiểu RousePro và WuDao, và theo dõi giá RousePro.",
  w_45:"Làm thế nào để thay đổi số tiền đã đầu tư",
  d_45:"Không thể thay đổi số tiền đã đầu tư, chỉ có thể rút tiền và đầu tư lại.",
  w_46:"Có thể rút bất kỳ số tiền nào từ hồ bơi không",
  d_46:"Không, chỉ có thể rút tiền từ hồ bơi khi số tiền trong hồ bơi không, sau khi rút hết tiền có thể rút tiếp.",
  w_47:"Có thể đổi RousePro sang tiền WuDao ở đâu",
  d_47:"Chỉ có thể đổi RousePro thành tiền WuDao trên trang web chính thức của RousePro.",
  w_48:"Có thể rút tiền hàng ngày không",
  d_48:"Có thể rút tiền RousePro hàng ngày, WuDao chỉ có thể rút tiền một lần khi nhận được.",
  w_49:"Có thể mua RousePro ở đâu",
  d_49:"Chỉ có thể nhận RousePro thông qua cơ chế thưởng hàng ngày, không thể mua RousePro.",
   w_50: "Điều gì không cần tin tưởng",
    d_50: "Không tin tưởng có nghĩa là không có cơ quan duy nhất nào có toàn quyền kiểm soát và cơ chế đồng thuận được thực hiện mà không có sự tin tưởng của bất kỳ người chơi cá nhân nào.",
    w_51: "Thỏa thuận là gì",
    d_51: "Giao thức là các quy tắc cần thiết cho cơ sở hạ tầng blockchain cho phép tài sản kỹ thuật số được trao đổi an toàn trên Internet.",
    w_52: "Cơ chế đồng thuận Consensus Mechanism là gì",
    d_52: "Cơ chế đồng thuận là một tập hợp đầy đủ các giao thức, ý tưởng và ưu đãi để kích hoạt một tập hợp các nút cho trạng thái của blockchain.",
    w_53: "Ví tiền điện tử Crypto là gì",
    d_53: "Đối với một ứng dụng được cài đặt trên máy tính hoặc điện thoại thông minh, khóa công khai hoặc khóa riêng để lưu trữ thông tin về giao dịch tài sản mật mã, NFT và chữ ký số",
    w_54: "Ví nóng là gì",
    d_54: "Ví nóng thường đề cập đến ví trao đổi tập trung, nơi chúng tôi không nắm giữ khóa riêng và chủ yếu được sử dụng cho các giao dịch thường xuyên như Binance, Oe, Bybit Exchange.",
    w_55: "Ví lạnh là gì",
    d_55: "Ví lạnh có nghĩa là chìa khóa cá nhân hoặc từ trợ ký nằm trong tay mình, ví dụ như ví TP, ví cáo nhỏ, phải có kiến thức quản lý ví cơ bản.",
    w_56: "Ví ngoại tuyến là gì",
    d_56: "Nó là một hình thức lưu trữ lạnh tương tự như các thiết bị vật lý (chủ yếu là ổ USB) cho phép tài sản tiền điện tử hoàn toàn ngoại tuyến. Ngay cả khi bị tấn công bởi tin tặc, tài sản không dễ bị đánh cắp, nhưng mọi giao dịch đều yêu cầu ủy quyền thiết bị vật lý và phải được lưu giữ cẩn thận các ghi chú",
    w_57: "Làm thế nào để giữ các từ phụ trợ",
    D_57: "Không được chụp ảnh hoặc chụp màn hình, một khi tin tặc tìm thấy từ trợ giúp, tài sản điện tử của chúng tôi rất nguy hiểm và cách tốt nhất là sao chép nó để bảo quản trong trường hợp mật khẩu bị quên hoặc thiết bị di động bị mất.",
    w_58: "Các ứng dụng phi tập trung (DApps) là gì",
    d_58: "Các ứng dụng sẽ hoạt động tự động trên công nghệ blockchain bằng cách sử dụng hợp đồng thông minh.",
    w_59: "Tổ chức tự trị phi tập trung (DAO) là gì",
    d_59: "Các nhóm được kiểm soát bởi các thành viên mà họ đại diện, không được kiểm soát bởi bất kỳ chính quyền trung ương nào",
    w_60: "Tài chính phi tập trung (DeFi) là gì",
    d_60: "Bằng cách sử dụng hợp đồng thông minh trên blockchain công cộng, nó có thể cho phép các dịch vụ tài chính ngang hàng mà không cần dựa vào các trung gian như sàn giao dịch hoặc ngân hàng.",
    w_61: "Metaverse là gì",
    d_61: "Metaverse là một phần của Web3, và sự trỗi dậy của NFT thể hiện đầy đủ kịch bản ứng dụng của Web3, một khái niệm thực tế ảo 3D đang phát triển, nơi mọi người có thể tương tác với bất cứ ai và bất cứ thứ gì dưới dạng Avatar, nơi họ có thể cảm nhận được thế giới ảo. NFT chứng minh quyền sở hữu trong siêu vũ trụ, nơi các tính năng và công nghệ khác nhau là siêu vũ trụ không thể thiếu sau này."
  }
}