<template>
    <div class="head">
        <router-link to="/">
            <div class="logoh">
                <div class="limg">
                    <img class="logo" src="@/assets/logo.png" />
                </div>
                <div class="lname">
                    RousePro
                </div>
            </div>


        </router-link>

        <div class="right">
            <div class="connect" @click="connect">{{ show_address }}</div>
            <!-- <img class="more" src="@/assets/more.png" @click="goNav"/> -->
            <van-icon name="wap-nav" size="30" @click="goNav" color="#FA8418" />
         <!--   <van-icon name="setting-o" size="24" @click="goNav" color="#ffffff" />-->

        </div>
    </div>
</template>

<script>


    import tp from 'tp-js-sdk'
    import chain from '../net'
    import { Notify } from 'vant';
    // import { login } from '@/util/api'
    export default {
        name: 'MzhHead',
        data() {
            return {
                showPopover: false,
                actions: [
                    { text: '中文' },
                    { text: 'English' },
                    { text: '한국어' },
                    { text: 'Tiếng Việt' },
                    { text: '日本語' },
                    { text: 'हिंदी' },
                    { text: 'Indonesia' },
                ],
                address: '',
                show_address: this.$t('m.connect')
            };
        },
        props: {
            msg: String
        },
        mounted() {
            const addr = sessionStorage.getItem("show_address");
            console.log(addr)
            if (addr) {
                this.show_address = addr
                this.$store.state.address = sessionStorage.getItem("address");
            }
            console.log("mounted")
        },
        created(){
            this.connect()
        },
        methods: {
            goNav() {
                this.$router.push('/nav')
            },
            async connect() {
                if (tp.isConnected()) {
                    let str1, str2, account
                    tp.getCurrentWallet().then(async (re) => {
                        if (re.data.blockchain !== 'bsc') {
                            await tp.getWallet({ walletTypes: ["bsc"], switch: true })
                            tp.getCurrentWallet().then((result) => {
                                account = result.data.address
                                this.address = account
                                str1 = account.substring(0, 8)
                                str2 = account.substring(36)
                                // this.show_address = str1 + "*****" + str2
                                // this.$store.commit("setAddress", account)
                                // sessionStorage.setItem("show_address", this.show_address);
                                // sessionStorage.setItem("address", account);
                            })

                        } else {
                            account = re.data.address
                            this.address = account
                            str1 = account.substring(0, 6)
                            str2 = account.substring(36)

                        }

                        this.show_address = str1 + "*****" + str2

                        this.$store.commit("setAddress", account)
                        sessionStorage.setItem("show_address", this.show_address);
                        sessionStorage.setItem("address", account);


                    })




                } else {
                    console.log("connect")
                    if (window.ethereum) {
                     //   let cfg = chain[56];
                        let cfg = chain[56];
                        const request = (window.ethereum).request;
                        try {
                            // 切换
                            await request({
                                method: "wallet_switchEthereumChain",
                                params: [{ chainId: cfg.chainId }],
                            });
                            const accounts = await request({ method: "eth_requestAccounts" });

                            this.address =accounts[0]
                            let str1 = accounts[0].substring(0, 6)
                            let str2 = accounts[0].substring(36)
                            this.show_address = str1 + "*****" + str2
                            this.$store.commit("setAddress", this.address)
                            sessionStorage.setItem("show_address", this.show_address);
                            sessionStorage.setItem("address", this.address);





                        } catch (e) {
                            Notify({ type: 'danger', message: this.$t('m.net_error') });
                            const err = e;
                            console.log(err);

                        }

                    } else {
                        Notify({ type: 'danger', message: this.$t('m.wallet') });
                    }
                }



            }
        },
    }
</script>

<style scoped>
    .logoh {
        display: flex;
        justify-content: space-between;
    }

    .logoh .limg {

    }

    .logoh .lname {
        font-size:18px;
        font-weight: bold;
        color:#FA8418;
        height:45px;
        line-height: 45px;
        padding-left: 5px;
    }

    .logo {
        width: 35px;
        height: 35px;
        margin-top: 5px
    }

    .more {
        width: 40px;
        height: 40px;
    }

    .head {
        display: flex;
        height: 60px;
        padding: 0px 25px;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: space-between;
        margin: auto;
        max-width: 500px;
        position: sticky;
        left: 0;
        top: 0;
        z-index: 10;
        /* background-color: #DDFDFA; */
        background: #000;


    }

    .right {
        display: flex;
        flex-direction: row;
        align-items: center;

    }

    .right img {
        width: 26px;
        height: 26px;
    }

    .connect {
        color: #EFEFEF;
        font-size: 13px;
        align-items: center;
        padding: 5px 10px;
        border:1px solid #FA8418;
        margin-right: 10px;
        border-radius: 6px;
    }

    .van-popover__content {
        background-color: #111536 !important;
    }
</style>
  