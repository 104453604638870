import {createApp} from 'vue'
import App from './App.vue'
import Web3 from 'web3'
import router from './router'
import store from './store'
//import i18n from './i18n'
import messages from './i18n'
import { createI18n } from 'vue-i18n'
let  i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem('localeLanguage') || "en",
    messages
})



import {
      Notify,Dialog,Icon, Popover, NavBar,Collapse, CollapseItem,Field,
      Tab, Tabs, Divider, Button, Empty , Search, Loading,Badge, Sidebar,
      SidebarItem,Card,Lazyload,Swipe,SwipeItem,Skeleton
} from 'vant';

const Vue = createApp(App).use(Swipe)
.use(SwipeItem)
.use(Lazyload,{loading:require('./assets/images/default.png')})
.use(Search).use(Skeleton).use(Badge).use(Loading).use(Empty)
.use(Button).use(Divider).use(Tab).use(Tabs).use(Field).use(Sidebar)
.use(SidebarItem).use(Card).use(Dialog).use(Icon)
.use(Notify).use(Collapse).use(CollapseItem).use(NavBar).use(Popover)
.use(i18n).use(store).use(router)
router.afterEach(() => {
  window.scrollTo(0,0);
});



Vue.config.productionTip = false
//Vue.prototype.Web3 = Web3



//在main.js引入
Vue.config.globalProperties.Web3 = Web3
//Vue.prototype.Web3 = Web3
Vue.mount('#app')
