export default {
  m: {   
      connect:'连接钱包',
      wallet:'请安装metamask钱包',
      no_connect:'未连接钱包',
      pledgeSuccess: "承诺成功",
      share_copy:"已复制",
      bind_success: "绑定成功",
      address_error: "地址错误",
      home:"首页",
      mining:"挖矿",
      me:"我的",
      lan:"语言",
      invite_num:"有效人数",
      invest:"投入",
      profit:'收益',
      release:"待释放",
      cash:'可提现',
      price:"价格",
      team_invest:"团队投入",
      pool:"铸造池",
      circulation:"流通量",
      small_card:"先锋卡牌",
      big_card:"创世卡牌",
      xq:"项目",
      account:"我的账户",
      max:"最大可铸造WuDao",
      nft:"我的卡牌",
      myinvite:"我的邀请",
      tjz:"推荐者",
      ztuser:"直推用户",
      view:"查看",
      nouser:"还没有推荐用户",
      copy:"已复制",
      ptjs:"平台介绍",
      ptjs2:"RousePro是一个非托管的、无需信任、去中心化的链上金融协议与PancakeSwap V3流动性协议合作提供服务和支持，以及众多区块链和Dex兼容和集成，并为流动性提供者寻求最大化的资金策略。平台采用自动执行的双而策略，使用自动触发来进行复利.管理、分配等实践。",
      jxsy:"静享收益",
      jxsy1:"邀请好友一起享受去中行产品带来丰厚收益。让你静态收益、团队收益、卡牌收益、你的财富在这里生生不息。",
      cxwf:"创新玩法",
      cxwf1:"无APP、无DAPP、无需钱包授权;颠覆传统玩法、合约开源、权限丢弃;双币铸造、买卖都涨、资产被动增值;你的能力大小、决定你的钱包大小",
      fxzl:"发行总量",
      fxzl1:"今天的RousePro，是要先有共识在有币。币的发行量:0流通由共识者发行，人人都是发币方理念:打破传统常规，让市场当发币方，用牛市托底，人人赚钱机制：双币铸造，极致通缩，螺旋上涨丢弃所有权限，机制自动执行，展现人性的恐惧与贪婪，一场没有硝烟的市场博弈RousePro收紧流通＋强制铸造升值",
      rhcy:"如何参与",
      rhcy1:"参与:0.1BNB起步转入合约地址，自动触发 金本位1.5%/天收益RousePro   2倍出局 RousePro只能卖不能买  提现就拉盘 拉盘就销毁 如果你控制不了所有人都卖出，RousePro就会上涨。",
      about:"可以通过下面方式来联络我们！",
      net_error:"网络错误",
      faq:"百科问答",
      rousev:"Rouse1.0 查询",
  },

  pro: {
      t1:"自动执行的复利",
      t2:"管理、分配平台",
      t3:"为流动性提供者寻求最大化的资金策略",
      p1:"RousePro是一个非托管的、无需信任、去中心化的链上金融协议与PancakeSwap V3流动性协议合作提供服务和支持，以及众多区块链和Dex兼容和集成，并为流动性提供者寻求最大化的资金策略。",
      p2:"平台采用自动执行的双而策略",
      p3:"使用自动触发来进行复利、管理、分配等实践",
      jx1:"邀请好友一起享受去中心产品带来丰厚收益",
      jx2:"静态收益",
      jx3:"团队收益",
      jx4:"卡牌收益",
      jx5:"你的财富在这里生生不息",
      cx1:"无APP",
      cx2:"无DAPP",
      cx3:"无需钱包授权",
      cx4:"颠覆传统玩法",
      cx5:"合约开源",
      cx6:"权限丢弃",
      cx7:"双币铸造",
      cx8:"买卖都涨",
      cx9:"资产被动增值",
      cx10:"你的能力大小",
      cx11:"决定你的钱包大小",
      fx1:"今天的RousePro，是要先有共识在有币",
      fx2:"币的发行量:21,000,000",
      fx3:"流通由共识者发行，人人都是发币方",
      fx4:"理念:",
      fx5:"打破传统常规",
      fx6:"让市场当发币方",
      fx7:"用牛市托底",
      fx8:"人人赚钱机制:",
      fx9:"双币铸造",
      fx10:"极致通缩",
      fx11:"螺旋上涨丢弃所有权限",
      fx12:"机制自动执行",
      fx13:"展现人性的恐惧与贪婪",
      fx14:"一场没有硝烟的市场博弈",
      fx15:"RousePro收紧流通＋强制铸造升值",
      fx16:"Rouse 代币分配",
      fx17:"A：RousePro",
      fx18:"总发行量：",
      fx19:"21,000,000",
      fx20:"B：WuDao",
      fx21:"总发行量：",
      fx22:"10,000,000",
      fx23:"铸造产出，通缩至：1,000,000",
      fx24:"开源",
      fx25:"弃权",
      fx26:"锁池",
      rh1:"参与:0.1BNB起步，转入合约地址",
      rh2:"自动触发 金本位1.5%/天收益",
      rh3:"RousePro 2倍出局",
      rh4:"RousePro只能卖不能买",
      rh5:"提现就拉盘 拉盘就销毁",
      rh6:"如果你控制不了所有人都卖出",
      rh7:"RousePro就会上涨",
  },

  shop:{
      menu:{
          home:'首页',
          category:'产品',
          faq:'问答',
          me:'我的',
      },

      bar:{
          sales:'畅销',
          new:'新品',
          recommend:'精选',
      },
      info:{
          recommend:'推荐',
          nodata:'没有更多数据了',
          loading:'加载中',
          nogoods:'该分类下没有商品',
          moreprops:'更多商品及属性，点击查看',
          detailtitle:'商品介绍',
          like:'相关商品',
          allcate:'产品分类',
          allgoods:'全部商品'
      }
  },

  faq:{
    w_1:'什么是RousePro',
    d_1:'RousePro 是一个双币策略建立在全链上金融协议，拥有一个完善的发行奖励机制，它结合了Defi，V3池，代币智能技术，无需DAPP 无APP，且发行前人人平等，RousePro与WuDao零私募，零预留，零节点，合约开源且放弃权限，目标创建一个真正的DAO自治社区，实现平等、公正、透明且民主的自治社区。',

    w_2:"如何参与RousePro自治社区",
    d_2:"每个钱包最少从0.1 $BNB开始，接下来的每个月投资上限会增加5个$BNB",

    w_3:"RousePro的亮点是什么",
    d_3:"操作方面简单易懂，不需链接DAPP让钱包更安全，双倍出局机制保障了奖励机制的公平与持久性，加上合约开源，放弃权限，技术方也无法动用资金池",

    w_4:"什么是合约开源",
    d_4:"开源智能合约更加透明和可审计，可以为投资者提供信任和保障。",

    w_5:"如何在RousePro 里赚取收益",
      // 新版2.0
    d_5:"每天产生1.5% 收益，每周日可领取7天的收益，持有两种币月收益从45%起，如果适当的分配持有币，上涨的币价将会给你带来惊喜的额外收益。",

    w_6:"如何查看双倍出局",
    d_6:"从钱包链接wudao.co以后，点击我的，从页面上看只要收益的版块达到投资的2倍，您的投资额和收益都会变成0，这个时候您就可以选择原点复投。",

    w_7:"双倍出局以后我的团队业绩还会保留吗",
    d_7:"是的，团队业绩还会保留并在您复投的时候一并发放收益。",

    w_8:"如何提取RousePro币",
    d_8:"RousePro转0.0001至RousePro合约地址即可提取您的RousePro币。",

    w_9:"如何提取WuDao币",
    d_9:"RousePro转0.0002至RousePro合约地址即可提取您的WuDao币。",

    w_10:"RousePro多久提取一次",
      // 新版2.0
    d_10:"RousePro 是每隔7天领取一次，领取时间为每周日00:00 开始直到23:59可领取一次，没达到时间点是无法发送，即便发出去以后也不会得到回复。",

    w_11:"为什么提取RousePro的时候合约显示错误",
      // 新版2.0
    d_11:"那是因为不在可领币的时间范围内，或自身的燃料费不够了。",

    w_12:"为什么有时候提取或投入以后钱包不显示",
    d_12:"大陆地区可能会因为网络原因导致，建议使用加速器。",
    w_13:"发送0.00001以后，RousePro的交易记录没显示",
    d_13:"这是钱包设置和显示的问题，建议到币安链游览器上进行查询。",

    w_14:"如何将RousePro币铸造成WuDao币",
    d_14:"首先要到官网查看可铸造WuDao币数量，然后将需要铸造的数量从RousePro转至RousePro合约地址即可。",

    w_15:"如何将WuDao币兑换成RousePro币",
    d_15:"将需要兑换的WuDao币，从WuDao转至WuDao的合约地址即可。",

    w_16:"为什么官网钱包里显示可提取WuDao的数量和实际到账的不一样",
    d_16:"比如显示可提取WuDao的数量为 1000，公式为 1000 X 2.5% X 80% =20个WuDao 。",

    w_17:"如何查询RousePro的价格",
    d_17:"你可以点击这里查询 ",

    w_18:"如何将RousePro闪兑BNB/USDT",
    d_18:"你可以在博饼pancakeswap.finance 用RousePro合约导入RousePro币以后，调整滑点12%然后直接闪兑成BNB/USDT",

    w_19:"如何打开官网",
    d_19:"选择钱包内的发现，输入官网地址：www.wudao.co，即可打开官网并查询账户数据及团队业绩",

    w_20:"为什么投入BNB后，官网没有数据显示",
    d_20:"新用户第一次投入BNB后，需进行一次提取RousePro币后，官网开始计算并显示各个数据。",
    //2.0
    w_21:"每天的提现时间是固定的吗",
    d_21:"ROUSEPRO币的提取时间是按照您的第一次提现的时间开始计算，24小时5分钟后即可进行第二天的提币。",
    //2.0
    w_22:"为什么每周题RousePro币的数量不一样",
    d_22:"每周的收益是按照您投入BNB当时USDT价格进行分配，根据您提取RousePro币的时间对等RousePro的币价计算您获取的RousePro币数量，RousePro币价格高的时候给的RousePro币数量少，RousePro币价格低的时候给的RousePro币数量多。",

    w_23:"RousePro币的上涨与下浮机制是怎么计算的",
    d_23:"当用户进行提取RousePro币的时候，RousePro币会上涨，当用户将RousePro币闪兑成BNB/USDT的时候，RousePro币会下浮。同时，BNB的上涨下浮也会影响到RousePro币的上涨下浮",

    w_24:"WuDao币的上涨与下浮机制是怎么计算的",
    d_24:"WuDao币没有实际的价格，但是它只对应RousePro币的汇率，简单的来说只要有您领取或卖出， WuDao兑换RousePro的汇率就会持续上涨，每一次从WuDao兑换成RousePro 也会上涨，因为WuDao的计算公式为：RousePro铸造池的量÷WuDao的流通量。",

    w_25:"按照1.5%的收益计算，双倍出局是133天，为什么说是66天",
    d_25:"按照1.5%的收益计算，纯静态的账户，合约检测双倍出局确实是133天，但是RousePro是由RousePro币、WuDao币双币螺旋式计算而获得收益的，当您持币达到一定时间，66天您的实际收益会超过2倍甚至更多",


    w_26:"什么是币圈",
    d_26:"参与加密货币项目或持有加密货币的人的圈子。",

    w_27:"什么是公钥",
    d_27:"公钥是加密资产钱包的地址。有了地址，只能将加密资产发送给该人。没有人可以提取或登录该人的帐户。",

    w_28:"什么是AMA ",
    d_28:"Ask Me Anything，通常指项目方或交易负责人举办的问答活动。",

    w_29:"什么是ATH",
    d_29:"代表历史最高点，代币达到其最高价格，是代币统计中的一个里程碑。",

    w_30:"什么是山寨币",
    d_30:"最初用于指任何不是比特币的加密货币，现在山寨币可能指任何市值相对较小的新加密货币。",

    w_31:"什么是Apeing In",
    d_31:"不经过事先调查，盲目购买 NFT 项目。",

    w_32:"什么是燃烧",
    d_32:"召回NFT。例如，如果原定由1万枚NFT组成的收藏品只售出5千枚，开发团队可能会决定“燃烧”剩余五千枚NFT",

    w_33:"什么是Defi",
    d_33:"去中心化金融缩短，正在公共区块链上构建无边界、无信任、点对点金融工具的生态系统，无需使用银行。DeFi应用程序被构建为开放和互连的，允许它们相互结合使用。",

    w_34:"什么是DYOR",
    d_34:" Do your own research 做你自己的研究，代表做你自己的研究。这个句子是用来提醒人们在投资某项资产之前要进行自己的调查。",

    w_35:"什么是DAO",
    d_35:"去中心化的自治组织，一个基于开源代码并由其用户管理的组织。DAO通常专注于一个特定的项目或任务，将传统企业的等级制度换写成区块链上的基准。",

    w_36:"什么是DAPP",
    d_36:"去中心化应用去中心化的应用程序，一种建立在区块链上的开源代码上的应用程序。DAPP独立于中心化的群体或人物而存在，通常通过奖励代币来激励用户维护它们。",

    w_37:"什么是DEX",
    d_37:"去中心化交易所去中心化的交易所，一个建立在区块链上的点对点加密货币交易所。一个DEX由其用户和智能合约运行，而不是由里面的人物或中心化机构运行。",

    w_38:"什么是Diamond Hands",
    d_38:"钻石手，一个暗示你极度看好某种资产的术语，无论市场波动、疑虑或价格大幅下跌，都没有计划出售。有人在加密货币或股票一天内下跌40%时仍坚持持有，被称为拥有钻石手。",

    w_39:"什么是二级市场",
    d_39:"也是流转中心，是买家与买家之间的互相流通的。官方预约的叫一级，玩家之间的买卖称为二级。",


    w_40:"什么是Fiat",
    d_40:"法币，由政府发行和监管的货币。",

    w_41:"什么是Gas Fee",
    d_41:"即燃料费，在以太坊中，每笔交易都需要使用ETH来手续费支付，假设从A账户转加密资产到B账户，过程中需要支付Gas Fee给帮忙完成交易的矿工，也称之为矿工费。币安智能链上的交易也一样。",


    w_42:"什么是Liquidity Pool",
    d_42:"流动性池。提供用户的资金合集，锁定在一个智能合约中，以促进 DeFi 平台上的交易。比如博饼平台。",

    w_43:"什么是铸造",
    d_43:"铸造，验证信息的过程，执行该过程的参与者被成为矿工。",

    w_44:"什么是NFT",
    d_44:"非同质化代币，一种数字真实性证书，用于分配和验证独特的数字或实物资产的流通。",

    w_45:"什么是链上",
    d_45:"链上，包括区块链上的任何交易或数据，区块链网络上的所有节点可见。",

    w_46:"什么是滑点",
    d_46:"滑点，加密货币的价格在下单与该订单最终成交之间可能发生变化，指报价与实际执行价格之间的差异。",

    w_47:"什么是智能合约",
    d_47:"智能合约，部署在区块链上的自执行代码，允许在没有任务的情况下进行交易。",

    w_48:"什么是Txn Hash",
    d_48:"交易哈希值的简称，或称交易ID。是一个用于代表特定交易的唯一标识符，携程一长串字母和数字。",

    w_49:"什么是Web3.0",
    d_49:"下一代互联网，强调用户对数据的掌控权、隐私保护、应用去中心化等特点。它致力于构建一个更加开放和民主的互联网。",

    w_50:"什么是无需信任",
    d_50:"无信任意味着没有单一的权威机构拥有完全的控制权，共识机制是在不信任任何单个参与者的情况下实现的。",

    w_51:"什么是协议",
    d_51:"协议是区块链基础设施所需的规则，它允许数字资产在互联网上安全地交换。",

    w_52:"什么是共识机制Consensus Mechanism",
    d_52:"共识机制是一套完整的协议、想法和激励机制，用于为区块链的状态启用一组节点。",

    w_53:"什么是加密钱包Crypto Wallet  ",
    d_53:"以安装在计算机或智能手机上的应用程序，用于存储加密资产交易、NFT 和数字签名信息的公钥或私钥。",

    w_54:"什么是热钱包",
    d_54:"热钱包通常是指中心化的交易所钱包，我们不掌握私钥，主要用于频繁交易，比如币安，欧易，bybit 交易所。",

    w_55:"什么是冷钱包",
    d_55:"冷钱包是指私钥或助记词掌控在自己手里，比如TP钱包，小狐狸钱包，必须要有基本的钱包管理知识。",

    w_56:"什么是离线钱包",
    d_56:"它是一种类似于物理设备（主要是 USB 驱动器）的冷存储形式，可以使加密资产完全离线。即便被骇客攻击也不容易被盗走资产，但每一次转账都需要物理设备授权，也必须保管好助记词。",

    w_57:"助记词如何保管",
    d_57:"千万不能拍照或截图，一但被骇客找到助记词，我们的电子资产就很危险，最好的方法是把它抄写下来保管，以防万一密码忘记或是手机设备遗失。",

    w_58:"什么是去中心化应用程序 (DApps) ",
    d_58:"将使用智能合约在区块链技术上自主运行的应用程序。",

    w_59:"什么是去中心化自治组织（DAO）",
    d_59:"由其代表的成员控制的团体，不受任何中央政府的控制。",

    w_60:"什么是去中心化金融（DeFi）",
    d_60:"通过在公共区块链上使用智能合约，它可以在不依赖交易所或银行等中介机构的情况下实现点对点金融服务。",

    w_61:"什么是元宇宙Metaverse ",
    d_61:" Metaverse 是 Web3 的一部分，而NFT的崛起充分展现了Web3的应用场景，Web3 是一个不断发展的 3D 虚拟现实概念，人们可以在其中以 Avatar 的形式与任何人和任何事物互动，在那里他们可以感受到虚拟世界。NFT证明了元宇宙里的拥有权，各个功能与技术都是以后不可缺一的元宇宙。",

  }
}
