export default {
  m: {
      connect: 'वालेट कनेक्ट करें',
    wallet:'कृपया मेटा मास्क वालेट संस्थापित करें',
    no_connect: 'वालेट कनेक्ट नहीं है',
    pledgeSuccess: "सफलता वादा करें",
    share_copy: "नक़ल किया",
    bind_success: "बाइन्ड सफलतापूर्वक",
    address_error: "पता त्रुटि",
    home: "घर पृष्ठ",
    mining: "मिनिंग",
    me:"मेरी",
    lan:"भाषा",
    invite_num: "वैध लोगों की संख्या",
    invest:"निवेश",
    profit:'Revenue',
    release:"के लिए",
    cash:'हटाना जाएगा',
    price:"मूल्य",
    team_invest: "टीम निवेश",
    pool: "पुल कास्टिंग",
    circulation: "सर्कुलेशन",
    small_card: "पायनर कार्ड",
    big_card:"जेनेसिस कार्ड",
    xq:"प्रोजेक्ट",
    account:"मेरा खाता",
    max:"अधिकतम कैस्टेबल वू",
    nft: "मेरी कार्ड",
    myinvite: "मेरा आमंत्रण",
    tjz:"सिफारिस करें",
    ztuser:"सीधा पुश उपयोक्ता",
    view:"दृश्य",
    nouser:"अभी कोई सिफारिस करनेवाला उपयोक्ता नहीं",
    copy: "नक़ल किया",
    ptjs:"प्लेटफॉर्म परिचय",
    ptjs2: "रूस सेवा और समर्थन प्रदान करने के लिए सेवा और समर्थन प्रदान करने के लिए पैनकेक्स वेप V3 लाइक्विडिटी प्रोटोकाल के साथ सहभागी है, विश्वास मुक्त, विश्वास मुक्त, डेक्स संगतता  Name",
    jxsy:"शांत बेनेफ़िट",
    jxsy1:"दोस्तों को चीन के बैंक के उत्पादनों के द्वारा आनंदित करने के लिए आनंदित करना चाहता है. तुम्हारे स्थिर आय, टीम आय, कार्ड",
    cxwf: "आवृत्तिक खेल",
    cxwf1: "कोई APP नहीं, कोई DAPP नहीं, कोई वालेट प्रमाणीकरण आवश्यक नहीं; पारंपरागत खेल, खुला स्रोत कंट्रैक्ट्स, और अनुमतियों को छोड़ दें; दुई सोनी कास्टिंग, दोनों के खरीद और विक्रिया उ",
    fxzl: "कुल उत्पन्न मात्रा",
    fxzl1: "आज के राउस डाओ मुद्रा होने से पहले संवेदन होने के बारे में संवेदन है. मुद्रा निर्गत: 0 संवेदन होल्डर के द्वारा विकास किया जाता है और हर कोई उत्पन्न है. संवेदन: पारंपरिक संवेदन को तोड़ता है, बाजार को उत्पन्न करने दे, नीचे समर्थन करने के लिए बूल बाजार के प्रय",
    rhcy:"कैसे सहभागी करना",
    rhcy1:"अभिवादन: 0.1BNB से प्रारंभ होता है, कंट्रांट पता में स्वचालित रूप से सोने का मानक 1.5%/दिन के लाभ में सोने का मानक ट्रिगर करें. रास केवल विक्रिया, निकाल, निकाल सकता है, निकाल स",
    about:"आप निम्न पद्धतियों में हमारे साथ संपर्क कर सकते हैं!",
    net_error: "नेटवर्क त्रुटि",
    faq:"एंक्लिकोपोर्ट क्यू&A",
    rousev:"Rouse 1.0 के बारे में जानकारी",
  },
  pro: {
    "t1": "स्वचालित लाभ परिशिष्ट",
    "t2": "प्रबंधन, वितरण प्लेटफॉर्म",
    "t3": "लिक्विडिटी प्रदाताओं के लिए अधिकतम निधि रणनीति की तलाश",
    "p1": "RousePro एक अनमौलिक, विश्वास-मुक्त, विकेंद्रित ब्लॉकचेन वित्त प्रोटोकॉल है जो PancakeSwap V3 लिक्विडिटी प्रोटोकॉल के साथ सहयोग करके सेवा और समर्थन प्रदान करता है, साथ ही कई ब्लॉकचेन और डेक्स के साथ संगत और एकीकृत है, और लिक्विडिटी प्रदाताओं के लिए अधिकतम निधि रणनीति की तलाश करता है।",
    "p2": "प्लेटफॉर्म स्वचालित दोहरी रणनीति को अपनाता है",
    "p3": "लाभ परिशिष्ट, प्रबंधन, वितरण आदि के अभ्यास के लिए स्वचालित ट्रिगर का उपयोग करें",
    "jx1": "मित्रों को आमंत्रित करें और विकेंद्रीकृत उत्पादों से उच्च आय का आनंद लें",
    "jx2": "स्थिर आय",
    "jx3": "टीम आय",
    "jx4": "कार्ड आय",
    "jx5": "आपकी धन यहाँ अंतहीन रूप से बढ़ता है",
    "cx1": "कोई ऐप नहीं",
    "cx2": "कोई डैप नहीं",
    "cx3": "वॉलेट प्राधिकरण की आवश्यकता नहीं",
    "cx4": "पारंपरिक खेल को उलटा",
    "cx5": "कॉन्ट्रैक्ट ओपन सोर्स",
    "cx6": "अधिकार त्याग",
    "cx7": "डबल टोकन मिसाई",
    "cx8": "खरीद-बिक्री दोनों बढ़ती",
    "cx9": "संपत्ति की निष्क्रिय वृद्धि",
    "cx10": "आपकी क्षमता का आकार",
    "cx11": "आपके बटुए का आकार तय करता है",
    "fx1": "आज के RousePro के लिए, पहले सहमति होनी चाहिए, फिर ही कोई सिक्का",
    "fx2": "सिक्का जारी मात्रा: 21,000,000",
    "fx3": "प्रवाह सहमतिकर्ताओं द्वारा जारी किया जाता है, हर कोई जारीकर्ता है",
    "fx4": "धारणा:",
    "fx5": "पारंपरिक नियमों को तोड़ दें",
    "fx6": "बाजार को जारीकर्ता बनाएं",
    "fx7": "बूम के साथ तल को समर्थन करें",
    "fx8": "हर कोई पैसे कमाने के लिए तंत्र:",
    "fx9": "डबल टोकन मिसाई",
    "fx10": "अत्यधिक संकुचन",
    "fx11": "सर्पिल वृद्धि सभी अधिकारों को त्याग",
    "fx12": "तंत्र स्वचालित रूप से कार्य करता है",
    "fx13": "मानव के भय और लालच का प्रदर्शन",
    "fx14": "धूम्रपान रहित बाजार की लड़ाई",
    "fx15": "RousePro संचरण को तंग + मजबूरी मूल्य वृद्धि",
    "fx16": "Rouse टोकन वितरण",
    "fx17": "ए: RousePro",
    "fx18": "कुल जारी मात्रा:",
    "fx19": "21,000,000",
    "fx20": "बी: वुदाओ",
    "fx21": "कुल जारी मात्रा:",
    "fx22": "10,000,000",
    "fx23": "मिसाई उत्पादन, डिफ्लेशन तक: 1,000,000",
    "fx24": "ओपन सोर्स",
    "fx25": "अधिकार त्याग",
    "fx26": "लॉक ब्रेक",
    "rh1": "भाग लेने के लिए: 0.1BNB से शुरू करें और अनुबंध पते पर भेजें",
    "rh2": "स्वचालित ट्रिगर द्वारा स्थिर मुद्रा 1.5%/दिन का आय",
    "rh3": "RousePro 2 गुना से वापस लेना",
    "rh4": "RousePro को बेचना ही संभव है, खरीदना संभव नहीं है",
    "rh5": "जब निकासी होती है तो मूल्य बढ़ता है, मूल्य बढ़ने पर तो विनाश होता है",
    "rh6": "यदि सभी लोगों के बिक्री पर नियंत्रण नहीं रख सकते हैं",
    "rh7": "RousePro बढ़ता है"
  },
    "shop": {
        "menu": {
            "home": "होम",
            "category": "उत्पाद",
            "faq": "FAQ",
            "me": "मेरा",
        },

        "bar": {
            "sales": "बिक्री",
            "new": "नया",
            "recommend": "चयनित",
        },
        "info": {
            "recommend": "चयनित",
            "nodata": "अधिक डेटा नहीं है",
            "loading": "लोडिंग...",
            "nogoods": "इस वर्ग में कोई सामान नहीं है",
            "moreprops": "अधिक सामान और गुण, देखने के लिए क्लिक करें",
            "detailtitle": "उत्पाद का विवरण",
            "like": "संबंधित सामान",
          "allcate": "उत्पाद वर्गीकरण",
          "allgoods": "सभी सामान"
        }
    },
    faq: {
    w_1: "RousePro क्या है",
    d_1: "RousePro एक पूर्ण श्रृंखला वित्तीय प्रोटोकॉल पर आधारित डबल कॉइन स्ट्रेटेजी है, जिसमें एक पूर्ण जारी इनाम प्रणाली है, यह Defi, V3 पूल, टोकन स्मार्ट टेक्नोलॉजी को शामिल करता है, कोई DAPP की आवश्यकता नहीं है, और लॉन्च पहले व्यक्ति बराबरी होती है, RousePro और WuDao को निजी प्लेसमेंट, रिजर्वेशन, नोड्स का कोई नहीं, और अनुबंध खोलना और अधिकारों का त्याग करना, लक्ष्य एक वास्तविक DAO स्वायत्त समुदाय बनाना है, जो समान, न्यायसंगत, पारदर्शी और लोकतांत्रिक स्वायत्त समुदाय की स्थापना करता है।",
    w_2: "RousePro स्वायत्त समुदाय में कैसे शामिल हों",
    d_2: "प्रत्येक बटुआ कम से कम 0.1 $BNB से शुरू होता है, और आने वाले प्रत्येक महीने की निवेश सीमा 5 $BNB बढ़ाई जाएगी।",
    w_3: "RousePro का विशेषता क्या है",
    d_3: "ऑपरेशन में सरलता समझ में आती है, डैप को जोड़ने की आवश्यकता नहीं होती है जिससे बटुआ अधिक सुरक्षित होता है, डबल आउट मैकेनिज़म ने इनाम प्रणाली की निष्पक्षता और स्थायित्व की गारंटी दी है, साथ ही अनुबंध खोलने, अधिकारों का त्याग करने के साथ, तकनीकी दल भी फंड पूल का उपयोग नहीं कर सकता।",
    w_4: "समझौते क्या हैं",
    d_4: "खुला स्मार्ट कॉन्ट्रैक्ट अधिक पारदर्शी और लेखा के लिए हो ता है, यह निवेशकों को विश्वास और सुरक्षा प्रदान कर सकता है।",
    w_5: "RousePro में कमाई कैसे होती है",
    "d_5": "हर दिन 1.5% का लाभ होता है, हर रविवार को 7 दिनों का लाभ ले जा सकते हैं, दो प्रकार की सिक्के रखकर मासिक लाभ 45% से शुरू होता है, यदि सिक्के का उचित वितरण किया जाता है, तो सिक्के की बढ़ती कीमत आपको आश्चर्यजनक अतिरिक्त लाभ देगी।",
    w_6: "डबल आउट कैसे देखें",
    d_6: "बटुआ वॉलेट को wudao.co से लिंक करने के बाद, मेरा पर क्लिक करें, पेज पर देखें कि आपकी आय का खंड प्रतिनिधित्व करता है कि जब आपकी निवेश दो गुना हो जाता है, तो आपका निवेश और आय शून्य हो जाती है, तब आप मूल बिंदु का चयन कर सकते हैं।",
    w_7: "डबल आउट के बाद मेरा टीम प्रदर्शन भी रखा जाएगा",
    d_7: "हां, टीम का प्रदर्शन भी बचाया जाएगा और जब आप पुनः निवेश करते हैं, तो लाभ भी एक साथ दिया जाएगा।",
    w_8: "RousePro मुद्रा को कैसे निकालें",
    d_8: "RousePro को 0.0001 से RousePro संयुक्त अनुबंध पते पर स्थानांतरित करें और आपका RousePro सिक्का निकाला जाएगा।",
    w_9: "WuDao मुद्रा को कैसे निकालें",
    d_9: "RousePro को 0.0002 से RousePro संयुक्त अनुबंध पते पर स्थानांतरित करें और आपका WuDao सिक्का निकाला जाएगा।",
    w_10: "RousePro कितने दिनों में निकालता है",
    "d_10": "RousePro हर 7 दिन बाद एक बार ले जा सकते हैं, लेने का समय हर रविवार 00:00 से 23:59 तक है, समय सीमा तक नहीं पहुંચने पर भेजना नहीं होगा, भले ही भेजा जाए तब भी उत्तर नहीं मिलेगा।",
    w_11: "RousePro निकालने पर अनुबंध में त्रुटि क्यों दिखाई देती है",
    "d_11": "ऐसा क्योंकि वह सिक्के लेने के समय सीमा के दायरे में नहीं है, या उसका फ्यूल चार्ज पर्याप्त नहीं है।",
    w_12: "कभी-कभी निकासी या निवेश के बाद बटुआ दिखाई नहीं देता है",
    d_12: "इसके कारण नेटवर्क की वजह से मुख्य भूमि में भारतीय क्षेत्र के उपयोगकर्ता हो सकते हैं, सुझाव दिया जाता है कि एक्सेलरेटर का उपयोग किया जाए।",
    w_13: "0.00001 भेजने के बाद, RousePro की लेन-देन का रिकॉर्ड नहीं दिखाई देता",
    d_13: "यह एक बटुआ सेटिंग और प्रदर्शन की समस्या हो सकती है, सुझाव दिया जाता है कि बीनांस चेन ब्राउज़र पर पूछताछ की जाए।",
    w_14: "RousePro सिक्के को WuDao सिक्के में कैसे बदला जाता है",
    d_14: "सबसे पहले वेबसाइट पर WuDao मुद्रा बदलने की संख्या को देखें, फिर RousePro से जोने वाले पते पर आवश्यक मात्रा को स्थानांतरित करें।",
    w_15: "WuDao सिक्के को RousePro सिक्के में कैसे बदलें",
    d_15: "जितनी भी WuDao मुद्राएं बदलनी हैं, WuDao से WuDao के अनुबंध पते पर स्थानांतरित करें।",
    w_16: "अधिकारी वेबसाइट में दिखाई गई निकासी की राशि और वास्तविक में प्राप्त नहीं होती",
    d_16: "जैसे 1000 के रूप में प्रदर्शित किया गया है, तो 1000 × 2.5% × 80% = 20 वी की संख्या का प्रदर्शन किया जाता है।",
    w_17: "RousePro की मूल्य की जांच कैसे करें",
    d_17: "आप यहाँ क्लिक करके जांच सकते हैं",
    w_18: "RousePro फ्लैश स्वॉप BNB/USDT कैसे करें",
    d_18: "आप pancakeswap.finance पर जाकर RousePro अनुबंध को निर्भर करते हुए RousePro मुद्रा को आयात करने के लिए स्लाइड स्ल ाइड कर सकते हैं।",
    w_19: "सुरक्षा टिप्स क्या हैं",
    d_19: "बटुआ वाले संवाद से पूछताछ करने और डेटा चोरी की रोकथाम के लिए तत्काल बाहर निकलने के बाद खुद को सुरक्षित करने की सलाह दी जाती है।",
    w_20: "क्या RousePro को समर्थन किया जाता है",
    d_20: "रौज़ दिन को उन योजनाओं का समर्थन करने के लिए प्रतिबद्ध किया गया है जो सार्वजनिक स्थितियों को समृद्धि और विकास में समर्थ बनाने के लिए भारत को समर्थ बनाने के लिए विशेष रूप से विशेषज्ञों का परिचय कराते हैं।",
      "w_21": "प्रतिदिन निकासी का समय निश्चित है क्या",
      "d_21": "ROUSEPRO सिक्का का निकासी समय आपके पहले निकासी के समय से शुरू होता है, 24 घंटे 5 मिनट बाद ही आप दूसरे दिन निकासी कर सकते हैं।",

      // 2.0
    "w_22": "क्यों हर सप्ताह RousePro सिक्के की संख्या अलग है",
    "d_22": "हर सप्ताह का लाभ आपके द्वारा BNB में निवेश किए गए USDT की कीमत के अनुसार वितरित किया जाता है, आपके द्वारा RousePro सिक्के लेने के समय के अनुरूप RousePro की कीमत पर आधारित आपके द्वारा प्राप्त RousePro सिक्के की संख्या की गणना की जाती है, जब RousePro सिक्के की कीमत अधिक होती है तो RousePro सिक्के की संख्या कम देती है, जब RousePro सिक्के की कीमत कम होती है तो RousePro सिक्के की संख्या अधिक देती है।",

    w_23: "RousePro और WuDao के साथ व्यापार कैसे करें",
    d_23: "बटुआ के अभ्यास स्थानांतरण के लिए आपको पहले क्लिक करना होगा, फिर बटुआ वाले कॉलम का उपयोग करके व्यापार करें",
    w_24: "डीपीजीटी का उपयोग कैसे करें",
    d_24: "पहले, RousePro के लिए एक बटुआ खोलें, और फिर उसमें से उधार लें, RousePro को WuDao के साथ स्वाप करें, और फिर WuDao का उपयोग करके डीपीजीटी बनाएं",
    w_25: "टोकन की गिरावट कैसे देखें",
    d_25: "प्रतिष्ठित ब्लॉक ब्राउज़र का उपयोग करें और अपना खाता खोलें, और फिर निवेश के खाते के स्थान को खोलें, तो आप टोकन विवरण को देख सकते हैं",
    w_26: "माइनिंग कैसे करें",
    d_26: "कोई भी उपयोगकर्ता इसे माइनिंग कर सकता है, जिसमें समय और उपयोगकर्ता की अपेक्षाएं को पूरा करने की जरूरत होती है",
    w_27: "क्या मुझे प्रत्येक रोज माइनिंग बिस्तर पर प्रतिबंध लगाया जाता है",
    d_27: "रौज़ दिन के माइनिंग बिस्तर प्रतिदिन प्रतिबंधित नहीं होते हैं, और इसे माइन किया जा सकता है।",
    w_28: "सीधा आदेश क्या है",
    d_28: "आपके पास प्रतिष्ठित कोड है, जो आपको निवेश और वापसी के लिए अवस्थित कर सकता है",
    w_29: "मेटा मास्क कैसे उपयोग करें",
    d_29: "आप यहाँ प्राप्त कर सकते हैं",
    w_30: "मैं अपनी विवेकांश डीपी जीटी को कहाँ चेक कर सकता हूँ",
    d_30: "विशिष्ट बिल्डर्स के लिए, प्राथमिक डैशबोर्ड पर क्लिक करें, और तालिका विश्लेषित करें।",
    w_31: "मैं कैसे अपना माइनिंग बिस्तर बदल सकता हूँ",
    d_31: "सबसे पहले, आपको सेटिंग को बदलने की आवश्यकता है, और फिर माइनिंग बिस्तर बदलें, और फिर आपको माइनिंग बिस्तर का चयन करने की आवश्यकता है।",
    w_32: "मैं कैसे अपनी व्यापारिक डीपीजीटी को बदल सकता हूँ",
    d_32: "सबसे पहले, आपको सेटिंग में जाना होगा, फिर डीपीजीटी सेक्शन में जाकर अपने व्यापारिक डीपीजीटी को बदल सकते हैं।",
    w_33: "क्या मैं किसी और के माइनिंग बिस्तर पर चेक आउट कर सकता हूँ",
    d_33: "हाँ, आप किसी भी अन्य बिल्डर के माइनिंग बिस्तर पर चेक आउट कर सकते हैं और इसे अपने खाते में बदल सकते हैं।",
    w_34: "मैं कैसे अपने माइनिंग बिस्तर की स्थिति को देख सकता हूँ",
    d_34: "आप अपने माइनिंग बिस्तर की स्थिति को चेक करने के लिए डैशबोर्ड पर जा सकते हैं और वहां आपको आपके माइनिंग बिस्तर की सभी जानकारी मिलेगी।",
    w_35: "मुझे निवेश के लिए टोकन का चयन कैसे करें",
    d_35: "आप टोकन चयन करने के लिए डैशबोर्ड पर जाकर विशेषज्ञों द्वारा प्रदत्त सुझावों का उपयोग कर सकते हैं और फिर उसमें निवेश कर सकते हैं।",
    w_36: "डीपीजीटी को कैसे वापस किया जाता है",
    d_36: "आप डैशबोर्ड पर जाकर डीजीटी के विकल्प पर क्लिक करके अपने डीजीटी को वापस कर सकते हैं और फिर बटुआ में लौट सकते हैं।",
    w_37: "रौज़ कैसे वापस करें",
    d_37: "आप रौज़ को वापस करने के लिए डैशबोर्ड पर जाकर बटुआ के विकल्प पर क्लिक करें और वहां आपको रौज़ को वापस करने के विकल्प मिलेंगे।",
    w_38: "मैं अपने खाते के लिए कितने बटुए खोल सकता हूँ",
    d_38: "आप अपने खाते के लिए कितने बटुए खोल सकते हैं, वहां आपको एक से अधिक बटुए खोलने की अनुमति होती है।",
    w_39: "मेरा खाता कैसे हैक हो गया",
    d_39: "अगर आपका खाता हैक हो गया है, तो आपको तुरंत अपना पासवर्ड बदलना चाहिए और अपने खाते को सुरक्षित रखने के लिए सुरक्षा सेटिंग्स को संशोधित करना चाहिए।",
    w_40: "डीजीटी में निवेश कैसे करें",
    d_40: "डैशबोर्ड पर जाएं, निवेश सेक्शन में जाएं, और फिर अपने पसंदीदा टोकन का चयन करें और निवेश करें।",
    w_41: "मुझे डीजीटी की अपडेट कैसे मिलेगी",
    d_41: "आपको डीजीटी की सभी अपडेट्स डैशबोर्ड पर उपलब्ध होंगी, ताकि आपको सभी नवीनतम जानकारी प्राप्त हो सके।",
    w_42: "मैं कैसे डीजीटी सेटिंग को बदल सकता हूँ",
    d_42: "आप डीजीटी सेटिंग को बदलने के लिए डैशबोर्ड पर जाएं, और वहां आपको विभिन्न सेटिंग विकल्प मिलेंगे।",
    w_43: "मैं अपने डीजीटी खाते से कैसे निकाल सकता हूँ",
    d_43: "डैशबोर्ड पर जाएं, अपने बटुए में जाएं और वहां से निकाल सकते हैं।",
    w_44: "मैं कैसे अपने व्यापारिक डीजीटी का पूर्वानुमान कर सकता हूँ",
    d_44: "आप व्यापारिक डीजीटी का पूर्वानुमान करने के लिए डैशबोर्ड पर जाएं और वहां पूर्वानुमान सेक्शन में जाएं।",
    w_45: "मुझे डीजीटी से कितनी कमाई हो रही है",
    d_45: "आप डैशबोर्ड पर जाकर अपनी कमाई की स्थिति की जांच कर सकते हैं।",
    w_46: "मैं कैसे डीजीटी बैलेंस की जांच कर सकता हूँ",
    d_46: "आप डैशबोर्ड पर जाकर अपने बटुए में जाकर अपने डीजीटी बैलेंस की जांच कर सकते हैं।",
    w_47: "मुझे कितने टोकन्स मिले",
    d_47: "आप डैशबोर्ड पर जाकर अपने खाते में अपने टोकन्स की स्थिति की जांच कर सकते हैं।",
    w_48: "मेरे खाते का पासवर्ड कैसे बदलें",
    d_48: "आप डैशबोर्ड पर जाकर सेटिंग्स में जाकर अपना पासवर्ड बदल सकते हैं।",
    w_49: "मैं कैसे अपने खाते को सुरक्षित रख सकता हूँ",
    d_49: "आप अपने खाते को सुरक्षित रखने के लिए डैशबोर्ड पर जाकर सुरक्षा सेटिंग्स में जाकर उपाय कर सकते हैं।",
    w_50: "मेरे टोकन्स कहां जाते हैं",
    d_50: "आपके टोकन्स आपके बटुए में जाते हैं, जो कि आप अपने खाते के माध्यम से निकाल सकते हैं।",
    w_51: "मैं अपने टोकन को कैसे खरीद सकता हूं",
    d_51: "आप टोकन खरीदने के लिए डैशबोर्ड पर जाकर 'खरीदें' सेक्शन में जा सकते हैं और वहां से आवश्यक जानकारी दर्ज करके टोकन खरीद सकते हैं।",
    w_52: "मेरे खाते में टोकन कैसे जमा करें",
    d_52: "आप डैशबोर्ड पर जाकर 'जमा' सेक्शन में जाकर आवश्यक जानकारी दर्ज करके टोकन जमा कर सकते हैं।",
    w_53: "मेरा डीजीटी खाता कैसे सक्रिय करें",
    d_53: "आप अपने डीजीटी खाते को सक्रिय करने के लिए डैशबोर्ड पर जाकर 'सक्रिय' सेक्शन में जा सकते हैं और वहां से आवश्यक जानकारी दर्ज करके खाता सक्रिय कर सकते हैं।",
    w_54: "मेरे टोकन्स को कैसे निकालें",
    d_54: "आप अपने टोकन्स को निकालने के लिए डैशबोर्ड पर जाकर 'निकालें' सेक्शन में जा सकते हैं और वहां से आवश्यक जानकारी दर्ज करके टोकन निकाल सकते हैं।",
    w_55: "मैं कैसे अपने खाते को ब्लॉक कर सकता हूं",
    d_55: "आप डैशबोर्ड पर जाकर 'सुरक्षा' सेक्शन में जाकर अपने खाते को ब्लॉक कर सकते हैं।",
    w_56: "मैं कैसे अपने खाते को अनब्लॉक कर सकता हूं",
    d_56: "आप डैशबोर्ड पर जाकर 'सुरक्षा' सेक्शन में जाकर अपने खाते को अनब्लॉक कर सकते हैं।",
    w_57: "मैं अपना पासवर्ड कैसे रीसेट कर सकता हूं",
    d_57: "आप डैशबोर्ड पर जाकर 'सुरक्षा' सेक्शन में जाकर अपना पासवर्ड रीसेट कर सकते हैं।",
    w_58: "मैं अपने टोकन्स की विवरण कैसे देख सकता हूं",
    d_58: "आप डैशबोर्ड पर जाकर 'खाता' सेक्शन में जा सकते हैं और वहां से अपने टोकन्स की विवरण देख सकते हैं।",
    w_59: "मैं अपने टोकन्स को किसी अन्य वालेट में कैसे ट्रान्सफर कर सकता हूं",
    d_59: "आप डैशबोर्ड पर जाकर 'ट्रान्सफर' सेक्शन में जाकर अपने टोकन्स को किसी अन्य वालेट में ट्रान्सफर कर सकते हैं।",
    w_60: "मैं अपने खाते की सुरक्षा कैसे बढ़ा सकता हूं",
    d_60: "आप डैशबोर्ड पर जाकर 'सुरक्षा' सेक्शन में जाकर अपने खाते की सुरक्षा बढ़ा सकते हैं जैसे कि डीजीटी और अन्य सुरक्षा उपाय।",
    w_61: "मैं अपने खाते की गोपनीयता कैसे सेट कर सकता हूं",
    d_61: "आप डैशबोर्ड पर जाकर 'सुरक्षा' सेक्शन में जाकर अपने खाते की गोपनीयता सेट कर सकते हैं जैसे कि अपने पासवर्ड को सुरक्षित रखना।"
  }
}