export default {
  m: {
    connect: 'Connect Wallet',
    wallet: 'Please install Metamask wallet',
    no_connect: 'Not connected',
    pledgeSuccess: "Pledge success",
    share_copy: "Copied",
    bind_success: "Bind success",
    address_error: "Address error",
    home: "Home",
    mining: "Mining",
    me: "Me",
    lan: "Language",
    invite_num: "Invites",
    invest: "Investment",
    profit: 'Profit',
    release: "To be released",
    cash: 'Withdrawable',
    price: "Price",
    team_invest: "Group investment",
    pool: "Casting Pool",
    circulation: "circulation volume",
    small_card: "Vanguard Card",
    big_card: "Creation Card",
    xq: "Project",
    account: "Account",
    max: "Max castable for WuDao",
    nft: "My NFT",
    myinvite: "My invitation",
    tjz: "Recommender",
    ztuser: "Direct push user",
    view: "view",
    nouser: "No recommended users yet",
    copy: "Copied",
    ptjs: "Platform introduction",
    ptjs2: "RousePro is a non-custodial, trustless, decentralized on-chain financial protocol that works with the PancakeSwap V3 liquidity protocol to provide service and support, as well as numerous blockchain and Dex compatibility and integrations, and for liquidity providers Seek maximizing capital strategies. The platform adopts an automatically executed dual strategy and uses automatic triggers to carry out compound interest, management, distribution and other practices.",
    jxsy: "Quietly enjoy the profits",
    jxsy1: "Invite friends to enjoy the huge benefits of BOC products. Let your static income, team income, card income, and your wealth continue to grow here.",
    cxwf: "Innovative gameplay",
    cxwf1: "No APP, no DAPP, no wallet authorization required; subversion of traditional gameplay, open source contracts, discarding permissions; dual-currency minting, increased buying and selling, and passive appreciation of assets; your ability determines the size of your wallet.",
    fxzl: "Total amount of issuance",
    fxzl1: "Today's RousePro requires consensus first before there is a currency. The circulation of the currency: 0 is issued by consensus makers, and everyone is the currency issuer. Concept: break the traditional rules, let the market be the currency issuer, and use The bull market supports the bottom, and everyone makes money. The mechanism: dual-currency casting, extreme deflation, spiral upwards, discarding all authority, automatic execution of the mechanism, showing the fear and greed of human nature, a market game without gunpowder. RousePro tightens circulation + forced casting appreciation.",
    rhcy: "How to participate",
    rhcy1: "Participation: 0.1BNB is transferred to the contract address at the beginning, and the gold standard is automatically triggered. 1.5%/day income RousePro is 2 times out. RousePro can only be sold and cannot be bought. Withdrawal, it will be pulled and destroyed. If you can't control everyone, RousePro will sell. It will go up.",
    about: "You can contact us through the contact information below!",
    net_error:"Network error",
    faq:"Q&A",
    rousev:"Inquiry about Rouse1.0",
  },
  "pro": {
    "t1": "Automated Compound Interest",
    "t2": "Management and Distribution Platform",
    "t3": "Maximizing Capital Strategies for Liquidity Providers",
    "p1": "RousePro is a non-custodial, trustless, decentralized on-chain financial protocol partnering with PancakeSwap V3 Liquidity Protocol to provide services and support, alongside compatibility and integration with numerous blockchains and DEXs, seeking to maximize capital strategies for liquidity providers.",
    "p2": "The platform employs an automatically executed dual-sided strategy",
    "p3": "Utilizing automatic triggers for compound interest, management, distribution, and other practices",
    "jx1": "Invite friends to enjoy lucrative returns from decentralized products together",
    "jx2": "Static",
    "jx3": "Team",
    "jx4": "Card",
    "jx5": "Your wealth grows continuously here",
    "cx1": "No App",
    "cx2": "No DApp",
    "cx3": "No Wallet Authorization Required",
    "cx4": "Revolutionizing Traditional Gameplay",
    "cx5": "Open-Source Contract",
    "cx6": "Permissionless",
    "cx7": "Dual-Token Minting",
    "cx8": "Prices Rise with Both Buys and Sells",
    "cx9": "Passive Asset Appreciation",
    "cx10": "Your Ability",
    "cx11": "Determines the Size of Your Wallet",
    "fx1": "Today, RousePro is about consensus before coins",
    "fx2": "Total Token Supply: 21,000,000",
    "fx3": "Circulation is issued by consensus holders, making everyone a token issuer",
    "fx4": "Philosophy:",
    "fx5": "Breaking Traditional Norms",
    "fx6": "Letting the Market Be the Token Issuer",
    "fx7": "Bull Market Support",
    "fx8": "Everyone Earns Mechanism:",
    "fx9": "Dual-Token Minting",
    "fx10": "Extreme Deflation",
    "fx11": "Spiral Rise with Abandonment of All Permissions",
    "fx12": "Automatically Executed Mechanism",
    "fx13": "Exposing Human Nature's Fear and Greed",
    "fx14": "A Market Game without Gunpowder",
    "fx15": "RousePro Tightens Circulation + Forced Minting Appreciation",
    "fx16": "Rouse Token Allocation",
    "fx17": "A: RousePro",
    "fx18": "Total Supply:",
    "fx19": "21,000,000",
    "fx20": "B: WuDao",
    "fx21": "Total Supply:",
    "fx22": "10,000,000",
    "fx23": "Minted Production, Deflating to: 1,000,000",
    "fx24": "Open-Source",
    "fx25": "Waive Right",
    "fx26": "Locked Pool",
    "rh1": "Participation: Starts at 0.1 BNB, transfer to contract address",
    "rh2": "Automatically Triggered Gold Standard 1.5%/Day Earnings",
    "rh3": "RousePro 2x Exit",
    "rh4": "RousePro Can Only Be Sold, Not Bought",
    "rh5": "Withdrawals Drive Prices Up, Price Rises Lead to Burning",
    "rh6": "If You Can't Control Everyone Selling",
    "rh7": "RousePro Will Rise"
  },
"shop": {
  "menu": {
        "home": "Home",
        "category": "Shop",
        "faq": "Q&A",
        "me": "Me"
  },
  "bar": {
    "sales": "Best",
        "new": "New",
        "recommend": "Recommend"
  },
  "info": {
    "recommend": "Rec",
        "nodata": "No more data available",
        "loading": "Loading...",
        "nogoods": "No products in this category",
        "moreprops": "More products and attributes, click to view",
        "detailtitle": "Product Description",
        "like": "Related Products",
    "allcate": "Product Category",
    "allgoods": "All Products"
  }
},
  faq:{
    w_1:'What is RousePro',
    d_1:'RousePro is a dual-coin strategy built on a full-chain financial protocol with a comprehensive issuance reward mechanism. It combines Defi, V3 pool, token smart technology, no need for DAPP, no APP, and equal issuance before distribution. RousePro and WuDao have zero private placement, zero reserves, zero nodes, contract open source, and abandon permissions, aiming to create a truly autonomous DAO community, achieving equality, fairness, transparency, and democratic autonomy.',
    w_2:"How to participate in the RousePro autonomous community",
    d_2:"Each wallet starts with a minimum of $0.1 BNB, and the investment limit will increase by 5 $BNB every month.",
    w_3:"What are the highlights of RousePro",
    d_3:"Simple and easy to operate, no need to link DAPP to make the wallet more secure. The double out mechanism guarantees the fairness and sustainability of the reward mechanism, coupled with open source contracts and the abandonment of permissions, so that the technical side cannot use the fund pool.",
    w_4:"What is open source contract",
    d_4:"Open source smart contracts are more transparent and auditable, providing trust and security for investors.",
    w_5:"How to earn income in RousePro",
    d_5: "With a daily yield of 1.5%, you can claim your 7-day earnings every Sunday. Holding two types of coins yields a monthly return starting from 45%. If you allocate your holdings appropriately, the rising coin prices will bring you surprising additional earnings.",
    w_6:"How to view double out",
    d_6:"After linking to wudao.co from the wallet, click on mine. As long as the income section on the page reaches twice the investment, your investment amount and income will both become 0. At this time, you can choose to reset.",
    w_7:"Will my team performance be retained after double out",
    d_7:"Yes, the team performance will be retained and the income will be distributed when you reinvest.",
    w_8:"How to withdraw RousePro coin",
    d_8:"Transfer RousePro to 0.0001 to the RousePro contract address to withdraw your RousePro coin.",
    w_9:"How to withdraw WuDao coin",
    d_9:"Transfer RousePro to 0.0002 to the RousePro contract address to withdraw your WuDao coin.",
    w_10:"How often does RousePro extract",
    d_10: "RousePro can be claimed once every 7 days, starting from 00:00 until 23:59 every Sunday. If you attempt to withdraw before the specified time, the transaction will not be sent, and even if it is sent, you will not receive a response.",
    w_11:"Why does the contract show an error when withdrawing RousePro",
    d_11: "That's because you're not within the allowable withdrawal time frame, or your gas fee is insufficient.",
    w_12:"Why does the wallet not show sometimes after withdrawal or investment",
    d_12:"In mainland China, it may be caused by network reasons, it is recommended to use an accelerator.",
    w_13:"After sending 0.00001, the transaction record of RousePro is not displayed",
    d_13:"This is a wallet setting and display issue, it is recommended to check on the Binance Chain Explorer.",
    w_14:"How to mint WuDao coins from RousePro coins",
    d_14:"First, check the quantity of WuDao coins that can be minted on the official website, and then transfer the required quantity from RousePro to the RousePro contract address.",
    w_15:"How to exchange WuDao coins for RousePro coins",
    d_15:"Transfer the WuDao coins you want to exchange to the WuDao contract address.",
    w_16:"Why is the amount of withdrawable WuDao coins displayed on the official website different from the actual amount received",
    d_16:"For example, if the displayed amount of withdrawable WuDao coins is 1000, the formula is 1000 X 2.5% X 80% = 20 WuDao.",
    w_17:"How to check the price of RousePro",
    d_17:"You can click here to check.",
    w_18:"How to swap RousePro for BNB/USDT",
    d_18:"You can import the RousePro contract on Pancakeswap.finance, adjust the slippage to 12%, and then swap directly for BNB/USDT.",
    w_19:"How to open the official website",
    d_19:"Select Discover in the wallet, enter the official website address: www.wudao.co, and you can open the official website to check account data and team performance.",
    w_20:"Why is there no data displayed on the official website after investing BNB",
    d_20:"For new users, after investing BNB for the first time, you need to withdraw RousePro coins once, and then the official website will start calculating and displaying various data.",
    "w_21": "Is the daily withdrawal time fixed?",
    "d_21": "The withdrawal time for ROUSEPRO coins is calculated from the time of your first withdrawal, and you can withdraw coins for the next day after 24 hours and 5 minutes.",
    w_22: "Why is the number of RousePro coins claimed each week different",
    d_22: "The weekly earnings are distributed based on the USDT price of BNB when you invested. The number of RousePro coins you receive is calculated based on the RousePro price at the time you claim. When the RousePro price is high, the number of RousePro coins given is less, and when the RousePro price is low, the number of RousePro coins given is more.",
    w_23:"How is the rise and fall mechanism of RousePro coins calculated",
    d_23:"When users withdraw RousePro coins, the price of RousePro coins will rise. When users swap RousePro coins for BNB/USDT, the price of RousePro coins will fall. At the same time, the rise and fall of BNB will also affect the rise and fall of RousePro coins.",
    w_24:"How is the rise and fall mechanism of WuDao coins calculated",
    d_24:"WuDao coins do not have an actual price, but they correspond to the exchange rate of RousePro coins. Simply put, as long as you claim or sell, the exchange rate of WuDao to RousePro will continue to rise. Every time you exchange WuDao for RousePro, the rate will also rise, because the calculation formula of WuDao is: the amount in the RousePro minting pool ÷ the circulation of WuDao.",
    w_25:"According to the calculation of 1.5% income, the double out is 133 days, why is it said to be 66 days",
    d_25:"According to the calculation of 1.5% income, for purely static accounts, the contract detects that the double out is indeed 133 days. However, RousePro earns income through the dual spiral calculation of RousePro coins and WuDao coins. When you hold coins for a certain period, your actual income will exceed 2 times or even more in 66 days.",
    w_26:"What is the crypto circle",
    d_26:"The circle of people participating in cryptocurrency projects or holding cryptocurrencies.",
    w_27:"What is a public key",
    d_27:"A public key is the address of a cryptographic asset wallet. With the address, only cryptographic assets can be sent to that person. No one can withdraw or log in to that person's account.",
    w_28:"What is AMA ",
    d_28:"Ask Me Anything, usually refers to a Q&A session held by project teams or trading leaders.",
    w_29:"What is ATH",
    d_29:"Represents the all-time high, when a token reaches its highest price, it is a milestone in token statistics.",
    w_30:"What is a meme coin",
    d_30:"Originally used to refer to any cryptocurrency other than Bitcoin, now meme coins may refer to any new cryptocurrency with a relatively small market capitalization.",
    w_31:"What is Apeing In",
    d_31:"Buying NFT projects blindly without prior investigation.",
    w_32:"What is Burning",
    d_32:"Recalling NFTs. For example, if a collection originally consisting of 10,000 NFTs sells only 5,000, the development team may decide to 'burn' the remaining 5,000 NFTs.",
    w_33:"What is Defi",
    d_33:"Decentralized Finance (DeFi) is the ecosystem of financial applications being built on public blockchains that do not rely on traditional intermediaries like banks. DeFi applications are built to be open and interconnected, allowing them to be combined in various ways.",
    w_34:"What is DYOR",
    d_34:"Do Your Own Research, representing the advice to conduct your own research before investing in any asset.",
    w_35:"What is DAO",
    d_35:"Decentralized Autonomous Organization, an organization managed by its members and not controlled by any central government.",
    w_36:"What is DAPP",
    d_36:"Decentralized Application, an application built on blockchain-based open source code. DApps exist independently of centralized entities or individuals and typically incentivize users to maintain them through token rewards.",
    w_37:"What is DEX",
    d_37:"Decentralized Exchange, a peer-to-peer cryptocurrency exchange built on blockchain. A DEX is run by its users and smart contracts, rather than by entities or individuals within it.",
    w_38:"What is Diamond Hands",
    d_38:"Diamond hands is a term implying extreme bullishness on a particular asset, with no plans to sell regardless of market fluctuations, doubts, or significant price drops. Someone holding through a 40% drop in a cryptocurrency or stock in a day is said to have diamond hands.",
    w_39:"What is the secondary market",
    d_39:"Also known as the circulation center, it is the mutual circulation between buyers and sellers. The first level refers to official appointments, and the transactions between players are called the second level.",
    w_40:"What is Fiat",
    d_40:"Fiat currency is currency issued and regulated by governments.",
    w_41:"What is Gas Fee",
    d_41:"Also known as the transaction fee. In Ethereum, each transaction requires the use of ETH to pay fees. For example, when transferring assets from account A to account B, Gas Fee is paid to miners who help complete the transaction, also known as miner fees. Transactions on the Binance Smart Chain are the same.",
    w_42:"What is Liquidity Pool",
    d_42:"A liquidity pool is a collection of funds provided by users, locked in a smart contract to facilitate trading on a DeFi platform. For example, Pancakeswap.",
    w_43:"What is Minting",
    d_43:"The process of validating information, with participants executing this process being called miners.",
    w_44:"What is NFT",
    d_44:"Non-Fungible Token, a digital certificate of authenticity used to distribute and verify the circulation of unique digital or physical assets.",
    w_45:"What is On-chain",
    d_45:"On-chain refers to any transaction or data on the blockchain, visible to all nodes on the blockchain network.",
    w_46:"What is Slippage",
    d_46:"Slippage refers to the potential change in the price of cryptocurrency between placing an order and the order being executed, indicating the difference between the quoted price and the actual execution price.",
    w_47:"What is Smart Contract",
    d_47:"A smart contract is self-executing code deployed on a blockchain, allowing transactions to occur without the need for intermediaries.",
    w_48:"What is Txn Hash",
    d_48:"Short for Transaction Hash, or transaction ID. It is a unique identifier used to represent a specific transaction, consisting of a long string of letters and numbers.",
    w_49:"What is Web3.0",
    d_49:"The next generation of the internet, emphasizing user control of data, privacy protection, and decentralized applications, among other features. It aims to build a more open and democratic internet.",
    w_50:"What is Trustless",
    d_50:"Trustless means that no single authority has complete control, and the consensus mechanism is achieved without trusting any single participant.",
    w_51:"What is Protocol",
    d_51:"A protocol is the set of rules required for blockchain infrastructure, allowing digital assets to be securely exchanged on the internet.",
    w_52:"What is Consensus Mechanism",
    d_52:"The consensus mechanism is a complete set of protocols, ideas, and incentive mechanisms used to enable a set of nodes to reach a state on the blockchain.",
    w_53:"What is Crypto Wallet",
    d_53:"An application installed on a computer or smartphone used to store public keys or private keys for transactions, NFTs, and digital signature information of cryptographic assets.",
    w_54:"What is Hot Wallet",
    d_54:"A hot wallet typically refers to centralized exchange wallets where we do not control the private keys, mainly used for frequent trading, such as Binance, OKEx, and Bybit exchanges.",
    w_55:"What is Cold Wallet",
    d_55:"A cold wallet refers to a wallet where the private keys or mnemonic phrases are controlled by oneself, such as TP Wallet and MetaMask. Basic wallet management knowledge is required.",
    w_56:"What is Offline Wallet",
    d_56:"It is a form of cold storage similar to a physical device (mainly USB drives), which can keep cryptographic assets completely offline. Even if hacked, it is not easy to steal assets, but every transfer requires authorization from the physical device and the mnemonic phrase must be kept safe.",
    w_57:"How to store mnemonic phrases safely",
    d_57:"Never take photos or screenshots, once hackers find the mnemonic phrase, our electronic assets are in danger. The best way is to write it down and keep it safe, in case you forget the password or lose your mobile device.",
    w_58:"What are Decentralized Applications (DApps)",
    d_58:"Applications that operate autonomously on blockchain technology using smart contracts.",
    w_59:"What is Decentralized Autonomous Organization (DAO)",
    d_59:"A group controlled by its represented members, not subject to control by any central government.",
    w_60:"What is Decentralized Finance (DeFi)",
    d_60:"DeFi enables peer-to-peer financial services without relying on intermediaries such as exchanges or banks, by using smart contracts on public blockchains.",
    w_61:"What is Metaverse",
    d_61:"Metaverse is part of Web3, and the rise of NFTs fully demonstrates the application scenarios of Web3. Web3 is a continuously developing 3D virtual reality concept, where people can interact with anyone and anything in the form of Avatars, and where they can experience the virtual world. NFTs prove ownership in the metaverse, and every function and technology is indispensable for the metaverse in the future.",
  }
}
