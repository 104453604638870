export default {
    m: {
        connect:'連接錢包',
        wallet:'請安裝metamask錢包',
        no_connect:'未連接錢包',
        pledgeSuccess: "承諾成功",
        share_copy:"已複製",
        bind_success: "綁定成功",
        address_error: "地址錯誤",
        home:"首頁",
        mining:"挖礦",
        me:"我的",
        lan:"語言",
        invite_num:"有效人數",
        invest:"投入",
        profit:'收益',
        release:"待釋放",
        cash:'可提現',
        price:"價格",
        team_invest:"團隊投入",
        pool:"鑄造池",
        circulation:"流通量",
        small_card:"先鋒卡牌",
        big_card:"創世卡牌",
        xq:"項目",
        account:"我的帳戶",
        max:"最大可鑄造WuDao",
        nft:"我的卡牌",
        myinvite:"我的邀請",
        tjz:"推薦者",
        ztuser:"直推用戶",
        view:"查看",
        nouser:"還沒有推薦用戶",
        copy:"已複製",
        ptjs:"平臺介紹",
        ptjs2:"RousePro是一個非托管的、無需信任、去中心化的鏈上金融協議與PancakeSwap V3流動性協議合作提供服務和支持，以及眾多區塊鏈和Dex兼容和集成，並為流動性提供者尋求最大化的資金策略。平臺採用自動執行的雙而策略，使用自動觸發來進行複利.管理、分配等實踐。",
        jxsy:"靜享收益",
        jxsy1:"邀請好友一起享受去中行產品帶來豐厚收益。讓你靜態收益、團隊收益、卡牌收益、你的財富在這裡生生不息。",
        cxwf:"創新玩法",
        cxwf1:"無APP、無DAPP、無需錢包授權;顛覆傳統玩法、合約開源、權限丟棄;雙幣鑄造、買賣都漲、資產被動增值;你的能力大小、決定你的錢包大小",
        fxzl:"發行總量",
        fxzl1:"今天的RousePro，是要先有共識在有幣。幣的發行量:0流通由共識者發行，人人都是發幣方理念:打破傳統常規，讓市場當發幣方，用牛市托底，人人賺錢機制：雙幣鑄造，極致通縮，螺旋上漲丟棄所有權限，機制自動執行，展現人性的恐懼與貪婪，一場沒有硝煙的市場博弈RousePro收緊流通＋強制鑄造升值",
        rhcy:"如何參與",
        rhcy1:"參與:0.1BNB起步轉入合約地址，自動觸發 金本位1.5%/天收益RousePro   2倍出局 RousePro只能賣不能買  提現就拉盤 拉盤就銷毀 如果你控制不了所有人都賣出，RousePro就會上漲。",
        about:"可以通過下面方式來聯絡我們！",
        net_error:"網絡錯誤",
        faq:"百科問答",
        rousev:"Rouse1.0 查詢",
    },
    "pro": {
        "t1": "自動執行的復利",
        "t2": "管理、分配平台",
        "t3": "為流動性提供者尋求最大化的資金策略",
        "p1": "RousePro是一個非托管的、無需信任、去中心化的鏈上金融協議，與PancakeSwap V3流動性協議合作提供服務和支持，以及眾多區塊鏈和Dex的兼容和集成，並為流動性提供者尋求最大化的資金策略。",
        "p2": "平台採用自動執行的雙面策略",
        "p3": "使用自動觸發來進行復利、管理、分配等實踐",
        "jx1": "邀請好友一起享受去中心產品帶來豐厚收益",
        "jx2": "靜態收益",
        "jx3": "團隊收益",
        "jx4": "卡牌收益",
        "jx5": "你的財富在這裡生生不息",
        "cx1": "無APP",
        "cx2": "無DAPP",
        "cx3": "無需錢包授權",
        "cx4": "顛覆傳統玩法",
        "cx5": "合約開源",
        "cx6": "權限丟棄",
        "cx7": "雙幣鑄造",
        "cx8": "買賣都漲",
        "cx9": "資產被動增值",
        "cx10": "你的能力大小",
        "cx11": "決定你的錢包大小",
        "fx1": "今天的RousePro，是要先有共識在有幣",
        "fx2": "幣的發行量:21,000,000",
        "fx3": "流通由共識者發行，人人都是發幣方",
        "fx4": "理念:",
        "fx5": "打破傳統常規",
        "fx6": "讓市場當發幣方",
        "fx7": "用牛市托底",
        "fx8": "人人賺錢機制:",
        "fx9": "雙幣鑄造",
        "fx10": "極致通縮",
        "fx11": "螺旋上漲丟棄所有權限",
        "fx12": "機制自動執行",
        "fx13": "展現人性的恐懼與貪婪",
        "fx14": "一場沒有硝煙的市場博弈",
        "fx15": "RousePro收緊流通＋強制鑄造升值",
        "fx16": "Rouse 代幣分配",
        "fx17": "A：RousePro",
        "fx18": "總發行量：",
        "fx19": "21,000,000",
        "fx20": "B：WuDao",
        "fx21": "總發行量：",
        "fx22": "10,000,000",
        "fx23": "鑄造產出，通縮至：1,000,000",
        "fx24": "開源",
        "fx25": "棄權",
        "fx26": "鎖池",
        "rh1": "參與:0.1BNB起步，轉入合約地址",
        "rh2": "自動觸發 金本位1.5%/天收益",
        "rh3": "RousePro 2倍出局",
        "rh4": "RousePro只能賣不能買",
        "rh5": "提現就拉盤 拉盤就銷毀",
        "rh6": "如果你控制不了所有人都賣出",
        "rh7": "RousePro就會上漲"
    },
    "shop": {
        "menu": {
            "home": "首頁",
            "category": "產品",
            "faq": "問題",
            "me": "我的"
        },

        "bar": {
            "sales": "暢銷",
            "new": "新品",
            "recommend": "精選"
        },
        "info": {
            "recommend": "推薦",
            "nodata": "沒有更多資料了",
            "loading": "加載中",
            "nogoods": "該分類下沒有商品",
            "moreprops": "更多商品及屬性，點擊查看",
            "detailtitle": "商品介紹",
            "like": "相關商品",
            "allcate": "產品分類",
            "allgoods": "全部商品"
        }
    },
    faq:{
        w_1:'什麼是RousePro',
        d_1:'RousePro 是一個雙幣策略建立在全鏈上金融協議，擁有一個完善的發行獎勵機制，它結合了Defi，V3池，代幣智能技術，無需DAPP 無APP，且發行前人人平等，RousePro與WuDao零私募，零預留，零節點，合約開源且放棄權限，目標創建一個真正的DAO自治社區，實現平等、公正、透明且民主的自治社區。',

        w_2:"如何參與RousePro自治社區",
        d_2:"每個錢包最少從0.1 $BNB開始，接下來的每個月投資上限會增加5個$BNB",

        w_3:"RousePro的亮點是什麼",
        d_3:"操作方面簡單易懂，不需鏈接DAPP讓錢包更安全，雙倍出局機制保障了獎勵機制的公平與持久性，加上合約開源，放棄權限，技術方也無法動用資金池",

        w_4:"什麼是合約開源",
        d_4:"開源智能合約更加透明和可審計，可以為投資者提供信任和保障。",


        w_5:"如何在RousePro 里賺取收益",
        d_5:"每天產生1.5%的收益，每週日可領取7天的收益。持有兩種幣的月收益從45%起，如果適當地分配持有幣種，上漲的幣價將會為你帶來驚喜的額外收益。",

        w_6:"如何查看雙倍出局",
        d_6:"從錢包鏈接www.wudao.co以後，點擊我的，從頁面上看只要收益的版塊達到投資的2倍，您的投資額和收益都會變成0，這個時候您就可以選擇原點復投。",

        w_7:"雙倍出局以後我的團隊業績還會保留嗎",
        d_7:"是的，團隊業績還會保留並在您復投的時候一併發放收益。",

        w_8:"如何提取RousePro幣",
        d_8:"RousePro轉0.0001至RousePro合約地址即可提取您的RousePro幣。",

        w_9:"如何提取WuDao幣",
        d_9:"RousePro轉0.0002至RousePro合約地址即可提取您的WuDao幣。",

        w_10:"RousePro多久提取一次",
        d_10:"RousePro是每隔7天領取一次，領取時間為每週日00:00開始直到23:59期間可領取一次。若未達到指定的時間點，則無法進行領取操作，即便發送請求也不會得到回復。",

        w_11:"為什麼提取RousePro的時候合約顯示錯誤",
        d_11:"那是因為不在可領取幣的時間範圍內，或是自身的燃料費不足了。",

        w_12:"為什麼有時候提取或投入以後錢包不顯示",
        d_12:"大陸地區可能會因為網絡原因導致，建議使用加速器。",
        w_13:"發送0.00001以後，RousePro的交易記錄沒顯示",
        d_13:"這是錢包設置和顯示的問題，建議到币安链游览器上進行查詢。",

        w_14:"如何將RousePro幣鑄造成WuDao幣",
        d_14:"首先要到官網查看可鑄造WuDao幣數量，然後將需要鑄造的數量從RousePro轉至RousePro合約地址即可。",

        w_15:"如何將WuDao幣兌換成RousePro幣",
        d_15:"將需要兌換的WuDao幣，從WuDao轉至WuDao的合約地址即可。",

        w_16:"為什麼官網錢包裡顯示可提取WuDao的數量和實際到賬的不一樣",
        d_16:"比如顯示可提取WuDao的數量為 1000，公式為 1000 X 2.5% X 80% =20個WuDao 。",

        w_17:"如何查詢RousePro的價格",
        d_17:"你可以點擊這裡查詢 ",

        w_18:"如何將RousePro閃兑BNB/USDT",
        d_18:"你可以在博餅pancakeswap.finance 用RousePro合約導入RousePro幣以後，調整滑點12%然後直接閃兑成BNB/USDT",

        w_19:"如何打開官網",
        d_19:"選擇錢包內的發現，輸入官網地址：www.wudao.co，即可打開官網並查詢賬戶數據及團隊業績",

        w_20:"為什麼投入BNB後，官網沒有數據顯示",
        d_20:"新用戶第一次投入BNB後，需進行一次提取RousePro幣後，官網開始計算並顯示各個數據。",

        "w_21": "每天的提現時間是固定的嗎？",
        "d_21": "ROUSEPRO幣的提取時間是按照您的第一次提現的時間開始計算，24小時5分鐘後即可進行第二天的提幣。",

        w_22: "為什麼每周提取RousePro幣的數量不一樣",
        d_22: "每週的收益是按照您投入BNB當時USDT的價格進行分配。根據您提取RousePro幣的時間對應RousePro的幣價來計算您獲取的RousePro幣數量。當RousePro幣價格高的時候，給的RousePro幣數量少；當RousePro幣價格低的時候，給的RousePro幣數量多。",

        w_23:"RousePro幣的上漲與下浮機制是怎麼計算的",
        d_23:"當用戶進行提取RousePro幣的時候，RousePro幣會上漲，當用戶將RousePro幣閃兑成BNB/USDT的時候，RousePro幣會下浮。同時，BNB的上漲下浮也會影響到RousePro幣的上漲下浮",

        w_24:"WuDao幣的上漲與下浮機制是怎麼計算的",
        d_24:"WuDao幣沒有實際的價格，但是它只對應RousePro幣的匯率，簡單的來說只要有您領取或賣出， WuDao兌換RousePro的匯率就會持續上漲，每一次從WuDao兌換成RousePro 也會上漲，因為WuDao的計算公式為：RousePro鑄造池的量÷WuDao的流通量。",

        w_25:"按照1.5%的收益計算，雙倍出局是133天，為什麼說是66天",
        d_25:"按照1.5%的收益計算，純靜態的賬戶，合約檢測雙倍出局確實是133天，但是RousePro是由RousePro幣、WuDao幣雙幣螺旋式計算而獲得收益的，當您持幣達到一定時間，66天您的實際收益會超過2倍甚至更多",


        w_26:"什麼是幣圈",
        d_26:"參與加密貨幣項目或持有加密貨幣的人的圈子。",

        w_27:"什麼是公鑰",
        d_27:"公鑰是加密資產錢包的地址。有了地址，只能將加密資產發送給該人。沒有人可以提取或登錄該人的帳戶。",

        w_28:"什麼是AMA ",
        d_28:"Ask Me Anything，通常指項目方或交易負責人舉辦的問答活動。",

        w_29:"什麼是ATH",
        d_29:"代表歷史最高點，代幣達到其最高價格，是代幣統計中的一個里程碑。",

        w_30:"什麼是山寨幣",
        d_30:"最初用於指任何不是比特幣的加密貨幣，現在山寨幣可能指任何市值相對較小的新加密貨幣。",

        w_31:"什麼是Apeing In",
        d_31:"不經過事先調查，盲目購買 NFT 項目。",

        w_32:"什麼是燃燒",
        d_32:"召回NFT。例如，如果原定由1萬枚NFT組成的收藏品只售出5千枚，開發團隊可能會決定“燃燒”剩余五千枚NFT",

        w_33:"什麼是Defi",
        d_33:"去中心化金融縮短，正在公共區塊鏈上構建無邊界、無信任、點對點金融工具的生態系統，無需使用銀行。DeFi應用程序被構建為開放和互連的，允許它們相互結合使用。",

        w_34:"什麼是DYOR",
        d_34:" Do your own research 做你自己的研究，代表做你自己的研究。這個句子是用來提醒人們在投資某項資產之前要進行自己的調查。",

        w_35:"什麼是DAO",
        d_35:"去中心化的自治組織，一個基於開源代碼並由其用戶管理的組織。DAO通常專注於一個特定的項目或任務，將傳統企業的等級制度換寫成區塊鏈上的基準。",

        w_36:"什麼是DAPP",
        d_36:"去中心化應用去中心化的應用程序，一種建立在區塊鏈上的開源代碼上的應用程序。DAPP獨立於中心化的群體或人物而存在，通常通過獎勵代幣來激勵用戶維護它們。",

        w_37:"什麼是DEX",
        d_37:"去中心化交易所去中心化的交易所，一個建立在區塊鏈上的點對點加密貨幣交易所。一個DEX由其用戶和智能合約運行，而不是由裡面的人物或中心化機構運行。",

        w_38:"什麼是Diamond Hands",
        d_38:"鑽石手，一個暗示你極度看好某種資產的術語，無論市場波動、疑慮或價格大幅下跌，都沒有計劃出售。有人在加密貨幣或股票一天內下跌90%的情況下仍然不出售的人被認為是鑽石手。",

        w_39:"什麼是二級市場",
        d_39:"也是流轉中心，是買家與買家之間的互相流通的。官方預約的叫一級，玩家之間的買賣稱為二級。",


        w_40:"什麼是Fiat",
        d_40:"法幣，由政府發行和監管的貨幣。",

        w_41:"什麼是Gas Fee",
        d_41:"即燃料費，在以太坊中，每筆交易都需要使用ETH來手續費支付，假設從A賬戶轉加密資產到B賬戶，過程中需要支付Gas Fee給幫忙完成交易的礦工，也稱之為礦工費。币安智能链上的交易也一樣。",


        w_42:"什麼是Liquidity Pool",
        d_42:"流動性池。提供用戶的資金合集，鎖定在一個智能合約中，以促進 DeFi 平台上的交易。比如博餅平台。",

        w_43:"什麼是鑄造",
        d_43:"鑄造，驗證信息的過程，執行該過程的參與者被成為礦工。",

        w_44:"什麼是NFT",
        d_44:"非同質化代幣，一種數字真實性證書，用於分配和驗證獨特的數字或實物資產的流通。",

        w_45:"什麼是鏈上",
        d_45:"鏈上，包括區塊鏈上的任何交易或數據，區塊鏈網絡上的所有節點可見。",

        w_46:"什麼是滑點",
        d_46:"滑點，加密貨幣的價格在下單與該訂單最終成交之間可能發生變化，指報價與實際執行價格之間的差異。",

        w_47:"什麼是智能合約",
        d_47:"智能合約，部署在區塊鏈上的自執行代碼，允許在沒有任務的情況下進行交易。",

        w_48:"什麼是Txn Hash",
        d_48:"交易哈希值的簡稱，或稱交易ID。是一個用於代表特定交易的唯一標識符，攜程一長串字母和數字。",

        w_49:"什麼是Web3.0",
        d_49:"下一代互聯網，強調用戶對數據的掌控權、隱私保護、應用去中心化等特點。它致力於構建一個更加開放和民主的互聯網。",

        w_50:"什麼是無需信任",
        d_50:"無信任意味著沒有單一的權威機構擁有完全的控制權，共識機制是在不信任任何單個參與者的情況下實現的。",

        w_51:"什麼是協議",
        d_51:"協議是區塊鏈基礎設施所需的規則，它允許數字資產在互聯網上安全地交換。",

        w_52:"什麼是共識機制Consensus Mechanism",
        d_52:"共識機制是一套完整的協議、想法和激勵機制，用於為區塊鏈的狀態啟用一組節點。",

        w_53:"什麼是加密錢包Crypto Wallet  ",
        d_53:"以安裝在計算機或智能手機上的應用程序，用於存儲加密資產交易、NFT 和數字簽名信息的公鑰或私鑰。",

        w_54:"什麼是熱錢包",
        d_54:"熱錢包通常是指中心化的交易所錢包，我們不掌握私鑰，主要用於頻繁交易，比如币安，歐易，bybit 交易所。",

        w_55:"什麼是冷錢包",
        d_55:"冷錢包是指私鑰或助記詞掌控在自己手裡，比如TP錢包，小狐狸錢包，必須要有基本的錢包管理知識。",

        w_56:"什麼是離線錢包",
        d_56:"它是一種類似於物理設備（主要是 USB 驅動器）的冷存儲形式，可以使加密資產完全離線。即便被駭客攻擊也不容易被盜走資產，但每一次轉賬都需要物理設備授權，也必須保管好助記詞。",

        w_57:"助記詞如何保管",
        d_57:"千萬不能拍照或截圖，一但被駭客找到助記詞，我們的電子資產就很危險，最好的方法是把它抄寫下來保管，以防萬一密碼忘記或是手機設備遺失。",

        w_58:"什麼是去中心化應用程序 (DApps) ",
        d_58:"將使用智能合約在區塊鏈技術上自主運行的應用程序。",

        w_59:"什麼是去中心化自治組織（DAO）",
        d_59:"由其代表的成員控制的團體，不受任何中央政府的控制。",

        w_60:"什麼是去中心化金融（DeFi）",
        d_60:"通過在公共區塊鏈上使用智能合約，它可以在不依賴交易所或銀行等中介機構的情況下實現點對點金融服務。",

        w_61:"什麼是元宇宙Metaverse ",
        d_61:"Metaverse 是 Web3 的一部分，而NFT的崛起充分展現了Web3的應用場景，Web3 是一個不斷發展的 3D 虛擬現實概念，人們可以在其中以 Avatar 的形式與任何人和任何事物互動，在那裡他們可以感受到虛擬世界。NFT證明了元宇宙裡的擁有權，各個功能與技術都是以後不可缺一的元宇宙。",
      }
}