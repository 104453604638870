export default {
  m: {
      connect:'接続ウォレット',
    wallet:'metamaskウォレットをインストールしてください',
    no_connect:'ウォレット未接続',
    pledgeSuccess: '成功を約束する',
    share_copy:'コピー済み',
    bind_success: 'バインド成功',
    address_error: 'アドレスエラー',
    home:'トップページ',
    mining:'鉱山を掘る',
    me:'私の',
    lan:'言語',
    invite_num:'有効人数',
    invest:'投入',
    profit:'収益',
    release:'リリース待ち',
    cash:'現金化可能',
    price:'価格',
    team_invest:'チーム投入',
    pool:'鋳造池',
    circulation:'流通量',
    small_card:'パイオニアカード',
    big_card:'創世カード',
    xq:'プロジェクト',
    account:'マイアカウント',
    max:'最大鋳造可能WuDao',
    nft:'マイカード',
    myinvite:'私の招待',
    tjz:'推薦者',
    ztuser:'ユーザーを直接プッシュ',
    view:'表示',
    nouser:'まだユーザーを推薦していない',
    copy:'コピー済み',
    ptjs:'プラットフォームの紹介',
    ptjs2:'RouseProはホスティングされていない、信頼されていない、非中心化されたチェーン上の金融プロトコルとPancakeSwap V 3流動性プロトコルが協力してサービスとサポートを提供し、多くのブロックチェーンとDexが互換性と統合され、流動性プロバイダのために最大化された資金戦略を求めている。プラットフォームは自動的に実行されるデュアル戦略を採用し、自動トリガを使用して複利・管理・分配などの実践を行う',
    jxsy:'静かに収益を享受する',
    jxsy1:'友人を招待して、ミッドライン製品を楽しむことで豊かな収益をもたらします。静的な収益、チーム収益、カード収益、あなたの富をここで生き続けるようにします',
    cxwf:'革新的な遊び方',
    cxwf1:'APPなし、DAPPなし、財布の授権不要、伝統的な遊び方の転覆、契約のオープンソース、権限の廃棄、二重貨幣鋳造、売買ともに上昇、資産の受動的な付加価値、あなたの能力の大きさ、財布の大きさの決定',
    fxzl:'発行総量',
    fxzl1:'今日のRouseProは、まず共通認識を持って貨幣を持っていなければならない。貨幣の発行量：0流通は共通認識者によって発行され、誰もが貨幣を発行する側の理念：伝統的な慣例を打破し、市場を貨幣を発行する側にし、牛市を底にし、誰もが儲かる仕組み：二重貨幣鋳造、極限デフレ、螺旋上昇はすべての権限を捨て、仕組みは自動的に実行し、人間性の恐怖と貪欲を示し、硝煙のない市場ゲームRouseProは流通を引き締める＋強制鋳造切り上げを行う',
    rhcy:'参加する方法',
    rhcy1:'参加：0.1BNBから契約先に移行し、自動的に金本位1.5%/日収益RousePro 2倍アウトRouseProを起動することができます。現金を買うことができなければ、プルを引いて破棄することができます。すべての人が売ることをコントロールできなければ、RouseProは上昇します',
    about:'次の方法で連絡してくれます！',
    net_error:'ネットワークエラー',
    faq:"百科クイズ",
    rousev:"Rouse1.0の調査",
  },
  pro: {
    "t1": "自動実行の複利",
    "t2": "管理、分配プラットフォーム",
    "t3": "流動性提供者のための最大資本戦略を追求",
    "p1": "RouseProは、非保管型、信頼不要のデセントラライズドオンチェーン金融プロトコルで、PancakeSwap V3流動性プロトコルと協力してサービスとサポートを提供し、多数のブロックチェーンとDEXとの互換性と統合を実現し、流動性提供者のための最大資本戦略を追求しています。",
    "p2": "プラットフォームは自動実行のダブルサイド戦略を採用",
    "p3": "自動トリガーを使用して、複利、管理、分配などの実践を行う",
    "jx1": "友人を招待して、デセントラライズド製品の豊かな収益を共に享受",
    "jx2": "静的収益",
    "jx3": "チーム収益",
    "jx4": "カード収益",
    "jx5": "あなたの富はここで絶えず増加します",
    "cx1": "アプリ無し",
    "cx2": "DApp無し",
    "cx3": "ウォレット認証不要",
    "cx4": "伝統的な遊び方を覆す",
    "cx5": "コントラクトオープンソース",
    "cx6": "権限捨てる",
    "cx7": "ダブルトークンミンティング",
    "cx8": "売買ともに値上がり",
    "cx9": "資産の被動的価値上昇",
    "cx10": "あなたの能力の大きさ",
    "cx11": "あなたのウォレットの大きさを決定",
    "fx1": "今日のRouseProは、コインがある前にコンセンサスを必要とします",
    "fx2": "コインの発行量: 21,000,000",
    "fx3": "流通はコンセンサスを持つ者によって発行され、誰もが発行元になれます",
    "fx4": "理念:",
    "fx5": "伝統的な規範を打破",
    "fx6": "市場を発行元にさせる",
    "fx7": "熊市をサポートする",
    "fx8": "皆が稼ぐ機制:",
    "fx9": "ダブルトークンミンティング",
    "fx10": "極限のデフレーション",
    "fx11": "螺旋的に上昇し、すべての権限を捨てる",
    "fx12": "メカニズムの自動実行",
    "fx13": "人間性の恐怖と貪欲を現す",
    "fx14": "煙のない市場ゲーム",
    "fx15": "RouseProの流通を縮小＋強制的ミンティングによる価値上昇",
    "fx16": "Rouseトークンの分配",
    "fx17": "A：RousePro",
    "fx18": "総発行量：",
    "fx19": "21,000,000",
    "fx20": "B：WuDao",
    "fx21": "総発行量：",
    "fx22": "10,000,000",
    "fx23": "ミンティングによる生産量、デフレーションで1,000,000まで",
    "fx24": "オープンソース",
    "fx25": "権限捨てる",
    "fx26": "ロックプール",
    "rh1": "参加: 0.1BNBから始まり、コントラクトアドレスに送金",
    "rh2": "自動トリガーで金本位1.5%/日の収益",
    "rh3": "RouseProの2倍で撤退",
    "rh4": "RouseProは売るだけで買うことはできません",
    "rh5": "引き出しすると値上がり、値上がると破棄されます",
    "rh6": "もしあなたが全員の売却をコントロールできないならば",
    "rh7": "RouseProは上昇します"
  },
  "shop": {
    "menu": {
      "home": "ホーム",
      "category": "商品",
      "faq": "FAQ",
      "me": "私",
    },

    "bar": {
      "sales": "売れ行き",
      "new": "新品",
      "recommend": "おすすめ",
    },
    "info": {
      "recommend": "おすすめ",
      "nodata": "もっともっとありません",
      "loading": "読み込み中",
      "nogoods": "このカテゴリーに商品はありません",
      "moreprops": "もっと商品や属性を確認するには、こちらをクリック",
      "detailtitle": "商品詳細",
      "like": "関連商品",
      "allcate": "商品分類",
      "allgoods": "全商品"

    }
  },
  faq:{
    w_1:'RouseProは何ですか',
    d_1:'RouseProは、完全なチェーン上金融プロトコルに基づくデュアルコイン戦略であり、完璧な発行リワードメカニズムを持っています。それはDefi、V3プール、トークンスマートテクノロジーを組み合わせ、DAPPやアプリを必要とせず、発行前には誰もが平等であり、RouseProとWuDaoはプライベートセール、リザーブ、ノードなしで、契約はオープンソースであり権限を放棄しています。その目標は、真のDAO自治コミュニティを作成し、平等、公正、透明で民主的な自治コミュニティを実現することです。',
    w_2:"RousePro自治コミュニティへの参加方法は",
    d_2:"各ウォレットは最低0.1 $BNBから始めることができます。その後の毎月の投資上限は5 $BNB増加します。",
    w_3:"RouseProのハイライトは何ですか",
    d_3:"操作は簡単でわかりやすく、DAPPへのリンクは不要でウォレットはより安全になります。ダブルアウトメカニズムはリワードメカニズムの公平性と持続性を保証し、契約はオープンソースであり、権限は放棄されており、技術者も資金プールにアクセスすることはできません。",
    w_4:"オープンソース契約とは何ですか",
    d_4:"オープンソースのスマートコントラクトはより透明性が高く、監査が可能であり、投資家に信頼と保護を提供できます。",
    w_5:"RouseProで収益を得る方法は",
    "d_5": "毎日1.5%の収益が発生し、毎週日曜日00:00から23:59までの間に7日間の収益を領収できます。2種類のコインを保有すると、月間収益は45%から始まります。コインを適切に配分して保有すれば、コイン価格の上昇は驚きの追加収益をもたらします。",
    w_6:"ダブルアウトをどのように確認しますか",
    d_6:"wudao.coにウォレットをリンクした後、[私の]をクリックします。ページ上で、収益が投資額の2倍に達すると、投資額と収益が0になります。この時点でリスタートを選択できます。",
    w_7:"ダブルアウト後、私のチームのパフォーマンスは維持されますか",
    d_7:"はい、チームのパフォーマンスは維持され、リスタート時に収益が一緒に支払われます。",
    w_8:"RouseProコインを引き出す方法は",
    d_8:"RouseProをRousePro契約アドレスに0.0001送金するだけで、RouseProコインを引き出すことができます。",
    w_9:"WuDaoコインを引き出す方法は",
    d_9:"RouseProをRousePro契約アドレスに0.0002送金するだけで、WuDaoコインを引き出すことができます。",
    w_10:"RouseProはどれくらいの頻度で抽出されますか",
    "d_10": "RouseProは7日ごとに領収できます。領収時間は毎週日曜日00:00から23:59までの間です。指定の時間に到達しないと送信できず、送信しても返信はありません。",
    w_11:"RouseProを引き出す際に契約がエラーを表示する理由は",
    "d_11": "それは領収可能な時間帯外にいるか、または自分のガス料金が不足しているためです。",
    w_12:"時々引き出しまたは投資後、ウォレットに表示されないのはなぜですか",
    d_12:"大陸地域ではネットワークの問題により発生することがあります。アクセラレータの使用をお勧めします。",
    w_13:"0.00001を送金した後、RouseProの取引履歴が表示されないのはなぜですか",
    d_13:"これはウォレットの設定と表示の問題です。Binanceチェーンブラウザで検索することをお勧めします。",
    w_14:"RouseProコインをWuDaoコインに変換する方法は",
    d_14:"まず、公式サイトでWuDaoコインの鋳造可能な数量を確認し、次に必要な数量をRouseProからRousePro契約アドレスに送金するだけです。",
    w_15:"WuDaoコインをRouseProコインに交換する方法は",
    d_15:"WuDaoコインをWuDaoの契約アドレスに送信するだけです。",
    w_16:"公式ウェブサイトのウォレットに表示されるWuDaoコインの引き出し可能な数量と実際の受け取りが異なる理由は",
    d_16:"例えば、引き出し可能なWuDaoコインの数量が1000である場合、公式の計算式は1000 × 2.5% × 80% = 20 WuDaoです。",
    w_17:"RouseProの価格を調べる方法は",
    d_17:"[こちら]をクリックして価格を調べることができます。",
    w_18:"RouseProをBNB/USDTに変換する方法は",
    d_18:"博饼pancakeswap.financeでRousePro契約をインポートした後、12％のスリッページを調整し、直接BNB/USDTに変換することができます。",
    w_19:"公式ウェブサイトを開く方法は",
    d_19:"ウォレット内の「発見」を選択し、公式ウェブサイトのアドレス：www.wudao.coを入力すると、公式ウェブサイトが開き、アカウントデータとチームのパフォーマンスを確認できます。",
    w_20:"BNBを投資した後、公式ウェブサイトにデータが表示されないのはなぜですか",
    d_20:"新規ユーザーは最初にBNBを投資した後、RouseProコインを一度引き出す必要があります。その後、公式ウェブサイトは各種データを計算して表示します。",
    "w_21": "毎日の引き出し時間は固定ですか",
    "d_21": "ROUSEPROコインの引き出し時間は、最初の引き出し時間から計算し、24時間5分後に翌日の引き出しが可能になります。",

    // 2.0
    "w_22": "なぜ毎週RouseProコインの数が違うのですか",
    "d_22": "毎週の収益は、あなたがBNBに投資した時のUSDTの価格に基づいて分配されます。RouseProコインを引き出した時間に、RouseProの価格に応じて得られるRouseProコインの数を計算します。RouseProコインの価格が高いときはRouseProコインの数が少なく、RouseProコインの価格が低いときはRouseProコインの数が多いとなります。",
    w_23:"RouseProコインの価格上昇および下降メカニズムはどのように計算されますか",
    d_23:"RouseProコインを引き出すとRouseProコインの価格が上昇し、RouseProコインをBNB/USDTに交換するとRouseProコインの価格が下落します。同時に、BNBの価格上昇および下降もRouseProコインの価格上昇および下落に影響を与えます。",
    w_24:"WuDaoコインの価格上昇および下落メカニズムはどのように計算されますか",
    d_24:"WuDaoコインには実際の価格がないが、RouseProコインのレートに対応しています。簡単に言えば、WuDaoコインを引き出すまたは売却するたびに、WuDaoからRouseProへのレートが持続的に上昇します。WuDaoの計算式は次のとおりです：RousePro鋳造プールの量÷WuDaoの流通量。",
    w_25:"1.5%の収益で計算すると、ダブルアウトは133日かかりますが、なぜ66日と言われていますか",
    d_25:"1.5%の収益で計算すると、純粋な静的アカウントの場合、契約は133日でダブルアウトを検出します。しかし、RouseProはRouseProコインとWuDaoコインの双方が関与する双方向のスパイラル収益です。一定期間コインを保持すると、66日で実際の収益は2倍以上になることがあります。",
    w_26:"仮想通貨界とは何ですか",
    d_26:"暗号資産プロジェクトに参加するか、暗号資産を保有する人々のコミュニティ。",
    w_27:"公開鍵とは何ですか",
    d_27:"公開鍵は暗号資産ウォレットのアドレスです。このアドレスには暗号資産を送ることしかできません。誰もがアカウントを引き出したりログインしたりすることはできません。",
    w_28:"AMAとは何ですか",
    d_28:"Ask Me Anything、通常はプロジェクトチームやトレーディング責任者が主催する質疑応答イベント。",
    w_29:"ATHとは何ですか",
    d_29:"史上最高値を表し、トークンがその最高価格に達したときの代表的なマイルストーンです。",
    w_30:"山寨コインとは何ですか",
    d_30:"元々はビットコイン以外の暗号通貨を指す用語でしたが、現在は市場価値が比較的低い新しい暗号通貨を指す際に使用されることが一般的です。",
    w_31:"ブロックチェーンとは何ですか",
    d_31:"データの分散型デジタル台帳であり、取引記録の連続したチェーンです。データはネットワークの参加者すべてによって管理され、一度書き込まれた情報は削除または変更することができません。",
    w_32:"暗号通貨とは何ですか",
    d_32:"暗号通貨はデジタルまたはバーチャル通貨の一種であり、暗号技術を使用して作成および管理されます。分散型台帳技術（ブロックチェーン）を使用して、中央集権型の機関や銀行に依存せずに送金および取引を行うことができます。",
    w_33:"スマートコントラクトとは何ですか",
    d_33:"スマートコントラクトは、自動的に成立し、特定の条件が満たされると自動的に実行される契約です。ブロックチェーン上でコード化され、ブロックチェーン上で実行されます。",
    w_34:"暗号通貨の採掘とは何ですか",
    d_34:"採掘とは、ブロックチェーン上のトランザクションを検証し、ブロックを追加するプロセスです。このプロセスにより、新しい暗号通貨が発行されます。",
    w_35:"DeFiとは何ですか",
    d_35:"DeFi（分散型ファイナンス）は、中央集権型の機関を介さずに、ブロックチェーンやスマートコントラクトを使用して金融サービスを提供する仕組みです。",
    w_36:"リスクを管理する方法は",
    d_36:"リスクを管理するためには、投資先の詳細な調査と検討が重要です。また、リスクを分散させるためにポートフォリオに異なる資産クラスを含めることも重要です。",
    w_37:"暗号通貨投資の利点は何ですか",
    d_37:"暗号通貨投資の利点には、高い利益の可能性、取引の透明性、市場の利用可能性が含まれます。",
    w_38:"暗号通貨投資のリスクは何ですか",
    d_38:"暗号通貨投資のリスクには、市場の不安定性、価格の急激な変動、セキュリティの問題が含まれます。",
    w_39:"暗号通貨ウォレットとは何ですか",
    d_39:"暗号通貨ウォレットは、暗号通貨を保管および管理するためのデジタルウォレットです。これにより、ユーザーは取引を行ったり、資産を管理したりすることができます。",
    w_40:"イニシャルクライアントオファリング（ICO）とは何ですか",
    d_40:"イニシャルクライアントオファリング（ICO）は、新しい暗号通貨プロジェクトが資金を調達するための手段です。一般的に、プロジェクトのトークンを購入することで資金を提供します。",
    w_41:"ノンファンジブルトークン（NFT）とは何ですか",
    d_41:"ノンファンジブルトークン（NFT）は、ブロックチェーン上で一意のデジタルアセットを表すトークンです。これらのトークンは、デジタルアート、バーチャルランド、ゲームアイテムなど、さまざまなデジタルアセットを表すことができます。",
    w_42:"ブロック報酬とは何ですか",
    d_42:"ブロック報酬は、ブロックチェーンのマイナーが新しいブロックを追加するために受け取る報酬です。これには新しい暗号通貨が含まれることがあります。",
    w_43:"トークンとは何ですか",
    d_43:"トークンは、ブロックチェーン上でデジタルアセットを表すデジタルトークンです。これらのトークンは、様々な用途や目的のために作成されることがあります。",
    w_44:"フィアット通貨とは何ですか",
    d_44:"フィアット通貨は、政府が発行および保証する通貨のことです。米ドルやユーロなどの法定通貨がこれに該当します。",
    w_45:"流動性プロバイダーとは何ですか",
    d_45:"流動性プロバイダーは、取引所やデフィプラットフォームに流動性を提供する個人や企業のことです。これにより、ユーザーは取引をより円滑に行うことができます。",
    w_46:"トランザクションとは何ですか",
    d_46:"トランザクションは、暗号通貨の送金や取引を行うためのプロセスです。これらのトランザクションは、ブロックチェーン上で記録され、透明性と安全性を提供します。",
    w_47:"マーケットキャピタリゼーションとは何ですか",
    d_47:"マーケットキャピタリゼーションは、特定の暗号通貨の市場全体の価値を示す指標です。これは、通貨の価格と供給量を掛け合わせて計算されます。",
    w_48:"コンセンサスアルゴリズムとは何ですか",
    d_48:"コンセンサスアルゴリズムは、ブロックチェーンネットワークで取引を確認し、新しいブロックを追加するためのプロセスです。これには、証明書の作成や承認などが含まれます。",
    w_49:"チェーンスワップとは何ですか",
    d_49:"チェーンスワップは、異なるブロックチェーン上でのトークンの交換プロセスです。これにより、ユーザーは異なるブロックチェーン間で資産を移動することができます。",
    w_50:"ノードとは何ですか",
    d_50:"ノードは、ブロックチェーンネットワークの一部であり、ネットワーク上で取引を検証し、新しいブロックを追加する役割を果たします。",
    w_51:"ダウンロードとは何ですか",
    d_51:"ダウンロードは、デジタルデータやファイルをインターネットなどのネットワークから自分のコンピュータやデバイスに移動するプロセスです。",
    w_52:"アップロードとは何ですか",
    d_52:"アップロードは、自分のコンピュータやデバイスからデジタルデータやファイルをインターネットなどのネットワークに移動するプロセスです。",
    w_53:"エアドロップとは何ですか",
    d_53:"エアドロップは、暗号通貨プロジェクトが一定の条件を満たすユーザーに無料でトークンを配布するプロセスです。これは、プロジェクトの認知度を高めるために行われることがあります。",
    w_54:"リスタートとは何ですか",
    d_54:"リスタートは、投資を引き出したり、プロジェクトを再開始することを指します。リスタート時には、収益が支払われ、新しいサイクルが開始されます。",
    w_55:"ダウンタウンとは何ですか",
    d_55:"ダウンタウンは、トークン価格が急激に下落することを指します。これは、市場の不安定性や売り圧力が高まった場合に発生することがあります。",
    w_56:"アップタウンとは何ですか",
    d_56:"アップタウンは、トークン価格が急激に上昇することを指します。これは、市場の安定性や買い圧力が高まった場合に発生することがあります。",
    w_57:"マイニングとは何ですか",
    d_57:"マイニングは、ブロックチェーン上で新しいブロックを追加するための計算プロセスです。これにより、新しい暗号通貨が発行されます。",
    w_58:"ブロックとは何ですか",
    d_58:"ブロックは、ブロックチェーン上でデータを保存するための単位です。これには、取引データや他の情報が含まれます。",
    w_59:"スリッページとは何ですか",
    d_59:"スリッページは、取引が実行される際の価格の変動のことを指します。これは、取引が行われる間に市場価格が変動することによって発生します。",
    w_60:"ボラティリティとは何ですか",
    d_60:"ボラティリティは、市場価格の変動の程度を示す指標です。市場がよりボラティルである場合、価格はより大きく変動します。",
    w_61:"メタバース（Metaverse）とは何ですか",
    d_61:"メタバース（Metaverse）は、Web3の一部であり、NFTの台頭はWeb3のアプリケーションシナリオを十分に示しています。Web3は、持続的に発展する3Dバーチャルリアリティの概念であり、人々はそこでアバターの形で誰とでも何とでも対話し、仮想世界を体験することができます。NFTは、メタバース内での所有権を証明し、すべての機能と技術が今後欠かせないものであることを示しています。"
  }
}