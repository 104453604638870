export default {
  m: {
    connect:'지갑에 연결하세요',
    wallet:'메타마스크 지갑을 설치해주세요',
    no_connect:'지갑에 연결되지 않았습니다',
    pledgeSuccess: "서약 성공",
    share_copy:"복사됨",
    bind_success: "바인드 성공",
    address_error:"주소 오류",
    home:"집",
    mining:"채굴",
    me: "내",
    lan: "언어",
    invite_num: "유효한 사람 수",
    invest: "몰입",
    profit:'수익',
    release: "준비 중",
    cash:'현금인출 가능',
    price: "가격",
    team_invest:"팀 투입",
    pool: "캐스트 풀",
    circulation: "유통량",
    small_card:"선봉카드",
    big_card:"창세카드",
    xq: "프로젝트",
    account: "내 계정",
    max: "최대 캐스트 WuDao",
    nft: "내 카드",
    myinvite: "내 초대",
    tjz: "추천자",
    ztuser:"사용자 밀어넣기",
    view: "보기",
    nouser: "추천 사용자 없음",
    copy: "복사됨",
    ptjs: "플랫폼 소개",
    ptjs2: "RousePro는 관리되지 않고 신뢰할 필요가 없는 탈중심적인 체인 금융 프로토콜입니다. PancakeSwap V3 유동성 프로토콜과 협력하여 서비스와 지원을 제공하고 수많은 블록체인과 Dex가 호환되고 통합되며 유동성 공급자를 위해 최대화된 자금 전략을 추구합니다. 플랫폼은 자동으로 실행되는 쌍방향 정책을 사용하고 자동 트리거를 사용하여 복리. 관리, 분배 등 실천을 합니다.",
    jxsy:"정적 수익",
    jxsy1:"친구를 초청하여 함께 중국은행 상품에 가서 많은 수익을 가져다 줍니다.정적 수익, 팀 수익, 카드 수익, 당신의 재산이 이곳에서 끊임없이 자라게 합니다.",
    cxwf: "혁신적인 플레이",
    cxwf1:"APP 없음, DAPP 없음, 지갑 권한 필요 없음; 전통적인 게임 뒤집기, 계약 오픈 소스, 권한 폐기; 듀얼 코인 주조, 매매 모두 상승, 자산 수동 증식; 당신의 능력 크기, 당신의 지갑 크기를 결정",
    fxzl: "전체 릴리즈",
    fxzl1:"오늘의 RousePro는 먼저 공감대가 있고 화폐가 있어야 한다.화폐의 발행량: 0유통은 공감대자가 발행한다. 모든 사람은 화폐발행자의 이념이다. 전통적인 관례를 타파하고 시장을 화폐발행자로 만들며 강세장으로 바닥을 치고 모든 사람이 돈을 버는 메커니즘: 쌍화폐주조, 극치디플레이션, 나선상승은 모든 권한을 버리고 메커니즘은 자동으로 집행한다. 인성의 공포와 탐욕을 보여준다. 총성이 없는 시장게임인 RousePro는 유통을 조이고 + 강제로 주조하여 평가절상한다.",
    rhcy:"어떻게 참여합니까",
    rhcy1:"참여: 0.1BNB 시작 계약 주소로 전입, 자동 트리거 금 본위 1.5%/일 수익 RousePro 2배 아웃 RousePro는 팔 수 있고 현금 인출은 할 수 없으면 당겨 당겨 없애고 모든 사람이 파는 것을 통제하지 못하면 RousePro는 상승한다.",
    about: "다음 방법으로 연락 주세요!",
    net_error:"네트워크 오류",
    faq:"백과문답",
    rousev:"Rouse1.0 검색",
  },
  pro: {
    "t1": "자동 실행되는 복리",
    "t2": "관리, 분배 플랫폼",
    "t3": "유동성 제공자들을 위한 최대화된 자금 전략 탐색",
    "p1": "RousePro는 비관리형, 신뢰 필요 없이 분산된 체인 상 금융 프로토콜로서 PancakeSwap V3 유동성 프로토콜과 협력하여 서비스와 지원을 제공하며, 수많은 블록체인 및 Dex와의 호환성 및 통합을 지원하고 유동성 제공자들을 위한 최대화된 자금 전략을 탐색합니다.",
    "p2": "플랫폼은 자동 실행되는 이중 전략을 채택합니다.",
    "p3": "자동 트리거를 사용하여 복리, 관리, 분배 등의 실천을 수행합니다.",
    "jx1": "친구들을 초대하여 디센트럴라이제드 제품으로부터 풍부한 수익을 함께 누리하세요.",
    "jx2": "정적 수익",
    "jx3": "팀 수익",
    "jx4": "카드 수익",
    "jx5": "여기서 당신의 재산은 끊임없이 증가합니다.",
    "cx1": "앱 없이",
    "cx2": "DAPP 없이",
    "cx3": "지갑 권한 부여 없이",
    "cx4": "전통적인 놀이법을 뒤집힙니다.",
    "cx5": "계약 오픈 소스",
    "cx6": "권한 포기",
    "cx7": "이중 화폐 발행",
    "cx8": "매매 모두 오름",
    "cx9": "자산의 수동적 가치상승",
    "cx10": "당신의 능력의 크기",
    "cx11": "당신의 지갑의 크기를 결정합니다.",
    "fx1": "오늘의 RousePro는 동의가 먼저 있어야 화폐가 있습니다.",
    "fx2": "화폐 발행량: 21,000,000",
    "fx3": "유통은 동의자들에 의해 발행되며, 모두가 발행자가 됩니다.",
    "fx4": "이념:",
    "fx5": "전통적인 규칙을 깨뜨릅니다.",
    "fx6": "시장을 발행자로 만듭니다.",
    "fx7": "황소시장으로 바닥을 받칩니다.",
    "fx8": "모두가 돈을 벌기 위한 메커니즘:",
    "fx9": "이중 화폐 발행",
    "fx10": "극한적인 디플레이션",
    "fx11": "나선형 상승으로 모든 권한을 포기합니다.",
    "fx12": "메커니즘의 자동 실행",
    "fx13": "인간의 두려움과 탐욕을 드러냅니다.",
    "fx14": "연기 없는 시장 경쟁",
    "fx15": "RousePro 유통을 축소 + 강제 발행으로 가치상승",
    "fx16": "Rouse 토큰 분배",
    "fx17": "A: RousePro",
    "fx18": "총 발행량:",
    "fx19": "21,000,000",
    "fx20": "B: WuDao",
    "fx21": "총 발행량:",
    "fx22":"10,000,000",
    "fx23": "발행 생산, 디플레이션까지: 1,000,000",
    "fx24": "오픈 소스",
    "fx25": "권한 포기",
    "fx26": "잠금 풀",
    "rh1": "참여: 0.1BNB부터 시작하여 계약 주소로 전송",
    "rh2": "자동 트리거로 금본위 1.5%/일 수익",
    "rh3": "RousePro 2배로 퇴장",
    "rh4": "RousePro는 팔기만 가능, 사기는 불가능",
    "rh5": "출금하면 가격상승, 가격상승하면 소멸됩니다",
    "rh6": "모든 사람의 매도를 통제할 수 없다면",
    "rh7": "RousePro는 상승합니다"
  },
  "shop": {
    "menu": {
      "home": "홈",
      "category": "상품",
      "faq": "FAQ",
      "me": "내 정보",
    },

    "bar": {
      "sales": "인기상품",
      "new": "신상품",
      "recommend": "추천",
    },
    "info": {
      "recommend": "추천",
      "nodata": "더 이상 데이터가 없음",
      "loading": "로딩중",
      "nogoods": "이 카테고리에는 상품이 없습니다",
      "moreprops": "더 많은 상품 및 속성을 보려면 클릭하세요",
      "detailtitle": "상품 상세",
      "like": "관련 상품",
      "allcate": "제품분류",
      "allgoods": "전체상품"
    }
  },
  faq:{
    w_1:'RousePro란 무엇인가요?',
    d_1:'RousePro는 전체 체인 금융 프로토콜 위에 구축된 이중 토큰 전략입니다. 완벽한 발행 보상 메커니즘을 갖추고 있으며 DeFi, V3 풀, 토큰 지능 기술을 결합하였습니다. DAPP 또는 APP이 필요하지 않으며 발행 전에 모든 사람에게 동등하게 제공됩니다. RousePro와 WuDao는 개인 판매, 예비 예약, 노드 없음, 계약 공개 및 권한 포기로 제공되며, 진정한 DAO 자치 커뮤니티를 구축하고 평등하고 공정하며 투명하고 민주적인 자치 커뮤니티를 실현하는 것을 목표로 합니다.',
    w_2:"RousePro 자치 커뮤니티에 어떻게 참여할 수 있나요",
    d_2:"지갑 당 최소 0.1 $BNB부터 시작하여 매월 투자 한도가 5 $BNB씩 증가합니다.",
    w_3:"RousePro의 하이라이트는 무엇인가요",
    d_3:"운영이 간단하고 이해하기 쉽고 DAPP에 연결할 필요가 없어 더 안전한 지갑을 제공합니다. 이중 아웃 시스템은 보상 메커니즘의 공정성과 지속 가능성을 보장하며, 오픈 소스 계약 및 권한 포기로 인해 기술 측면에서도 자금 풀을 동원할 수 없습니다.",
    w_4:"계약 공개란 무엇인가요",
    d_4:"오픈 소스 스마트 계약은 투명하고 감사 가능하며 투자자에게 신뢰와 안전을 제공할 수 있습니다.",
    w_5:"RousePro에서 수익을 어떻게 얻을 수 있나요",
    "d_5": "매일 1.5%의 수익이 발생하며, 매주 일요일 00:00부터 23:59까지 7일간의 수익을 받을 수 있습니다. 두 종류의 코인을 소유할 경우, 월 수익은 45%부터 시작되며, 코인을 적절하게 분배할 경우, 코인 가격의 상승은 놀라운 추가 수익을 줄 수 있습니다.",
    w_6:"더블 아웃을 어떻게 확인할 수 있나요",
    d_6:"wudao.co에 지갑을 연결한 후 내 것을 클릭하면 수익 섹션에서 투자액이 2배가 되면 투자액과 수익이 모두 0이 됩니다. 이 때 기존 수익을 재투자할 수 있습니다.",
    w_7:"더블 아웃 이후에도 내 팀의 성과는 유지될까요",
    d_7:"예, 팀의 성과는 유지되며 재투자 시 함께 수익이 지급됩니다.",
    w_8:"RousePro 토큰을 어떻게 인출할 수 있나요",
    d_8:"RousePro를 0.0001로 RousePro 계약 주소로 전송하여 RousePro 토큰을 인출할 수 있습니다.",
    w_9:"WuDao 토큰을 어떻게 인출할 수 있나요",
    d_9:"RousePro를 0.0002로 RousePro 계약 주소로 전송하여 WuDao 토큰을 인출할 수 있습니다.",
    w_10:"RousePro는 얼마나 자주 추출되나요",
    "d_10": "RousePro는 7일마다 한 번씩 받을 수 있으며, 매주 일요일 00:00부터 23:59까지 받을 수 있습니다. 접수 시점에 도달하지 못하면 송신이 불가능하며, 송신 후에도 응답을 받지 못합니다.",
    w_11:"RousePro를 인출할 때 계약이 오류로 표시되는 이유는 무엇인가요",
    "d_11": "그는 코인을 받을 수 있는 시간 범위에 없거나, 자신의 연료비가 부족하기 때문입니다.",
    w_12:"때때로 인출 또는 투자한 후 지갑에 표시되지 않는 이유는 무엇인가요",
    d_12:"대륙 지역은 네트워크 문제로 인해 발생할 수 있으며 가속기 사용을 권장합니다.",
    w_13:"0.00001을 전송한 후에 RousePro의 거래 기록이 표시되지 않는 이유는 무엇인가요",
    d_13:"이는 지갑 설정 및 표시 문제로 권장 사항은 Binance Chain Explorer에서 조회하는 것입니다.",
    w_14:"RousePro를 WuDao로 돌릴 수 있나요",
    d_14:"먼저 공식 웹 사이트에서 대체 가능한 WuDao 토큰의 양을 확인한 다음 RousePro에서 필요한 양을 RousePro 계약 주소로 전송하면 됩니다.",
    w_15:"WuDao를 RousePro로 교환하는 방법은 무엇인가요",
    d_15:"교환하려는 WuDao 토큰 을 WuDao의 계약 주소로 보내면 됩니다.",
    w_16:"공식 웹 사이트 지갑에서 인출 가능한 WuDao의 수량과 실제로 받는 수량이 다른 이유는 무엇인가요",
    d_16:"예를 들어 인출 가능한 WuDao의 수량이 1000으로 표시되면 공식 공식은 1000 X 2.5% X 80% =20 WuDao입니다.",
    w_17:"RousePro의 가격을 어떻게 확인할 수 있나요",
    d_17:"여기를 클릭하여 확인할 수 있습니다.",
    w_18:"RousePro를 BNB/USDT로 어떻게 스왑할 수 있나요",
    d_18:"pancakeswap.finance에서 RousePro 계약을 가져온 후 RousePro 토큰으로 조정하여 12%의 슬리피지를 설정하고 BNB/USDT로 직접 스왑할 수 있습니다.",
    w_19:"공식 웹 사이트를 어떻게 열 수 있나요",
    d_19:"지갑에서 발견을 선택한 후 공식 웹 사이트 주소 www.wudao.co를 입력하면 공식 웹 사이트를 열고 계정 데이터 및 팀 성과를 조회할 수 있습니다.",
    w_20:"BNB를 투자한 후 공식 웹 사이트에 데이터가 표시되지 않는 이유는 무엇인가요",
    d_20:"새로운 사용자는 처음에 BNB를 투자한 후 RousePro 토큰을 인출해야 공식 웹 사이트가 모든 데이터를 계산하고 표시하기 시작합니다.",
    "w_21": "매일의 출금 시간은 고정되어 있습니까",
    "d_21": "ROUSEPRO 코인의 출금 시간은 첫 번째 출금 시간부터 측정되며, 24시간 5분 후에 다음 날의 출금이 가능합니다.",
    // 2.0
    "w_22": "왜 매주 RousePro 코인의 수량이 다르게 나오나요",
    "d_22": "매주의 수익은 당시 BNB에 투자한 USDT 가격에 따라 분배되며, RousePro 코인을 철수한 시점에 RousePro의 가격에 맞추어 얻게 될 RousePro 코인의 수량이 결정됩니다. RousePro 코인의 가격이 높할 때 RousePro 코인의 수량은 적게 주어지며, RousePro 코인의 가격이 낮할 때 RousePro 코인의 수량은 많이 주어집니다.",
    w_23:"RousePro 토큰의 상승 및 하락 메커니즘은 어떻게 계산되나요",
    d_23:"RousePro 토큰을 인출하면 RousePro 토큰이 상승하고 RousePro 토큰을 BNB/USDT로 스왑하면 RousePro 토큰이 하락합니다. 동시에 BNB의 상승 및 하락도 RousePro 토큰의 상승 및 하락에 영향을 줍니다.",
    w_24:"WuDao 토큰의 상승 및 하락 메커니즘은 어떻게 계산되나요",
    d_24:"WuDao 토큰에는 실제 가격이 없지만 RousePro 토큰의 환율에 해당합니다. 간단히 말해 인출하거나 판매할 때마다 WuDao로 RousePro를 환율하면 계속 상승합니다. WuDao를 RousePro로 환전할 때마다 상승하며 WuDao의 계산 공식은 다음과 같습니다: RousePro Minting Pool 양 ÷ WuDao 유통 양입니다.",
    w_25:"1.5% 수익으로 계산하면 더블 아웃이 133일이지만 66일이라고 말하는 이유는 무엇인가요",
    d_25:"1.5% 수익으로 계산할 때 정적 계정, 계약 검사 더블 아웃은 실제로 133일입니다. 그러나 RousePro는 RousePro 토큰 및 WuDao 토큰의 이중 토큰 스파이럴로 수익을 얻기 때문에 일정 시간이 경과하면 66일에 실제 수익이 2배 이상이 될 것입니다.",
    w_26:"암호통화란 무엇인가요",
    d_26:"암호화폐 프로젝트에 참여하거나 암호화폐를 보유하는 사람들의 공동체입니다.",
    w_27:"공개 키(Public Key)란 무엇인가요",
    d_27:"공개 키는 암호화 자산 지갑의 주소입니다. 주소가 있으면 암호화 자산을 해당 사람에게만 전송할 수 있습니다. 누구도 해당 사람의 계정을 인출하거나 로그인할 수 없습니다.",
    w_28:"AMA란 무엇인가요",
    d_28:"Ask Me Anything의 약어로 일반적으로 프로젝트 팀 또는 거래 책임자가 주최하는 질문과 답변 행사를 가리킵니다.",
    w_29:"ATH란 무엇인가요",
    d_29:"ATH는 역사상 최고점을 나타냅니다. 토큰이 최고 가격에 도달하는 것은 토큰의 마일스톤입니다.",
    "w_30": "무엇인가요 샤넬",
    "d_30": "최초에는 비트코인이 아닌 모든 암호화폐를 가리키는 용어였지만, 이제는 샤넬은 상대적으로 작은 시가총액의 새로운 암호화폐를 가리킬 수 있습니다.",
    "w_31": "Apeing In은 무엇인가요",
    "d_31": "사전 조사 없이 NFT 프로젝트를 맹목적으로 구매하는 것입니다.",
    "w_32": "소각이 무엇인가요",
    "d_32": "NFT를 회수합니다. 예를 들어, 예정된 수집품의 10,000개의 NFT 중 5,000개만 판매되었다면, 개발 팀은 남은 5,000개의 NFT를 '소각'하기로 결정할 수 있습니다.",
    "w_33": "DeFi란 무엇인가요",
    "d_33": "DeFi는 중앙 은행을 사용하지 않고 공개 블록체인 상에 경계 없고 신뢰 없는 개인 간 금융 도구를 구축하는 생태계를 줄여쓴 것입니다. DeFi 애플리케이션은 개방적이고 상호 연결되어 있으며 상호 조합이 가능하도록 구축됩니다.",
    "w_34": "DYOR란 무엇인가요",
    "d_34": "자신의 연구를 하십시오. 자신의 연구를 수행하십시오. 이 문구는 투자하기 전에 자신의 조사를 수행해야 함을 상기시키는 데 사용됩니다.",
    "w_35": "DAO란 무엇인가요",
    "d_35": "DAO(분산 자율 조직)는 오픈 소스 코드를 기반으로 하며 사용자가 관리하는 조직입니다. DAO는 일반적으로 특정 프로젝트나 작업에 중점을 두며 전통적인 기업의 등급 구조를 블록체인 상의 기준으로 바꿉니다.",
    "w_36": "DAPP란 무엇인가요",
    "d_36": "DAPP(분산 애플리케이션)은 오픈 소스 코드 위에 구축된 블록체인 상의 애플리케이션입니다. DAPP는 중앙 집중식 집단이나 개인과 독립적으로 존재하며 사용자가 유지보수하기 위해 토큰 보상을 통해 보상합니다.",
    "w_37": "DEX란 무엇인가요",
    "d_37": "DEX(분산형 거래소)는 블록체인 상의 피어 투 피어 암호화폐 거래소입니다. DEX는 그 사용자와 스마트 계약에 의해 운영되며 내부 인물이나 중앙화된 기관에 의해 운영되지 않습니다.",
    "w_38": "다이아몬드 핸즈란 무엇인가요",
    "d_38": "다이아몬드 핸즈는 특정 자산을 매우 긍정적으로 평가하는 용어입니다. 시장의 변동, 의심 또는 가격 대폭 하락에도 불구하고 판매 계획이 없습니다. 암호화폐나 주식이 하루에 40% 하락해도 여전히 보유하고 있는 사람들을 가리킵니다.",
    "w_39": "이중 시장이란 무엇인가요",
    "d_39": "이중 시장은 사이트와 사이트 사이의 상호 교환을 나타냅니다. 공식 예약을 빼앗기면 일찍 시장이라고 하고, 플레이어 간 거래는 이중 시장이라고 합니다.",
    "w_40": "Fiat은 무엇인가요",
    "d_40": "정부에 의해 발행 및 감독되는 화폐입니다.",
    "w_41": "Gas Fee는 무엇인가요",
    "d_41": "Gas Fee는 이더리움에서 각 거래마다 ETH를 사용하여 수수료를 지불해야 합니다. 예를 들어 A 계정에서 B 계정으로 암호화폐를 전송하는 경우 거래를 완료하기 위해 Gas Fee를 지불하여 거래를 도와준 마이너에게 지불됩니다. 이를 마이닝 비용이라고도 합니다. 바이낸스 스마트 체인에서도 동일합니다.",
    "w_42": "유동성 공급 풀은 무엇인가요",
    "d_42": "유동성 공급 풀은 DeFi 플랫폼 내에서 거래를 촉진하기 위해 한 스마트 계약에 잠겨있는 사용자 자금 풀입니다. 예를 들어, 주사위 게임 플랫폼 등이 있습니다.",
    "w_43": "주조란 무엇인가요",
    "d_43": "정보를 확인하는 프로세스로,이 프로세스를 수행하는 참여자를 채 굴자라고합니다.",
    "w_44": "NFT란 무엇인가요",
    "d_44": "NFT(비교 가능하지 않은 토큰)는 고유 한 디지털 또는 물리적 자산의 배포 및 인증을 위한 디지털 인증서입니다.",
    "w_45": "체인 상의은 무엇인가요",
    "d_45": "체인 상에는 블록체인 네트워크 상의 모든 거래 또는 데이터가 포함됩니다.",
    "w_46": "슬리피지는 무엇인가요",
    "d_46": "암호화폐의 가격이 주문과 해당 주문이 최종 처리되는 사이에 변경 될 수 있습니다. 이는 가격 제시 및 실제 실행 가격 간의 차이를 나타냅니다.",
    "w_47": "스마트 계약은 무엇인가요",
    "d_47": "블록체인에 배포된 자체 실행 코드로, 거래없이 거래를 수행 할 수 있습니다.",
    "w_48": "Txn 해시란 무엇인가요",
    "d_48": "Txn 해시는 특정 거래를 나타내는 고유 식별자 또는 거래 ID입니다. 긴 문자 및 숫자 문자열로 구성됩니다.",
    "w_49": "웹3.0은 무엇인가요",
    "d_49": "다음 세대 인터넷으로 데이터 소유권, 개인 정보 보호, 탈중앙화 애플리케이션 등을 강조합니다. 더 개방적이고 민주적 인 인터넷을 구축하는 데 초점을 맞추고 있습니다.",
    "w_50": "신뢰가 필요 없다는 것은 무엇을 의미하나요",
    "d_50": "신뢰 없음은 단일 권한 기관이 완전한 통제력을 가지지 않음을 의미하며, 합의 메커니즘은 어떠한 단일 참여자도 믿지 않고도 작동합니다.",
    "w_51": "프로토콜은 무엇인가요",
    "d_51": "프로토콜은 블록체인 인프라에 필요한 규칙으로, 디지털 자산이 인터넷 상에서 안전하게 교환되도록 합니다.",
    "w_52": "합의 메커니즘은 무엇인가요",
    "d_52": "합의 메커니즘은 블록체인 상태에 대한 노드 그룹을 활성화하는 완전한 프로토콜, 아이디어 및 인센티브 메커니즘입니다.",
    "w_53": "암호화 지갑은 무엇인가요",
    "d_53": "암호화 지갑은 암호 자산 거래, NFT 및 디지털 서명 정보를 저장하는 공개 키 또는 개인 키의 어플리케이션입니다.",
    "w_54": "핫 지갑은 무엇인가요",
    "d_54": "핫 지갑은 일반적으로 중앙 집중식 거래소 지갑을 가리킵니다. 우리는 개인 키를 소유하지 않습니다. 빈번한 거래에 사용됩니다. 예를 들어, 바이낸스, 유이, 바이빗 거래소 등이 있습니다.",
    "w_55": "콜드 지갑은 무엇인가요",
    "d_55": "콜드 지갑은 개인 키 또는 니모닉을 자체 소유하는 지갑입니다. 예를 들어, TP 지갑, Metamask 지갑 등이 있습니다. 기본적인 지갑 관리 지식이 있어야 합니다.",
    "w_56": "오프라인 지갑은 무엇인가요",
    "d_56": "오프라인 지갑은 전적으로 오프라인으로 만든 물리적 장치(주로 USB 드라이브)로, 암호화 자산을 완전히 오프라인으로 보관합니다. 해커 공격을 받아도 자산을 도난당하기 어렵지만 각 송금은 물리적 장치 승인이 필요하고 니모닉을 안전하게 보관해야 합니다.",
    "w_57": "니모닉은 어떻게 안전하게 보관할 수 있나요",
    "d_57": "사진 찍거나 스크린샷을 찍어서는 안 됩니다. 해커가 니모닉을 찾으면 전자 자산이 매우 위험합니다. 최상의 방법은 니모닉을 손으로 적어서 안전하게 보관하는 것입니다. 비밀번호를 잊어버리거나 휴대폰 장치를 잃어버릴 경우를 대비해야 합니다.",
    "w_58": "DApps은 무엇인가요",
    "d_58": "스마트 계약을 사용하여 자체적으로 실행되는 응용 프로그램입니다.",
    "w_59": "DAO는 무엇인가요",
    "d_59": "DAO(분산 자치 조직)는 회원이 통제하는 단체로 어떠한 중앙 정부에도 의존하지 않습니다.",
    "w_60": "DeFi는 무엇인가요",
    "d_60": "공공 블록체인을 사용하여 중개 업체나 은행에 의존하지 않고 개인 간 금융 서비스를 제공합니다.",
    "w_61": "메타버스는 무엇인가요",
    "d_61": "메타버스는 Web3의 일부이며 NFT의 등장으로 Web3의 응용 프로그램 시나리오를 보여줍니다. Web3는 Avatar 형식으로 어디서나 누구나 상호 작용할 수 있는 계속 발전하는 3D 가상 세계입니다. 여기에서 NFT는 메타버스의 소유권을 증명하며 각 요소와 기술은 불가능한 메타버스의 필수적인 요소입니다."
  }
}