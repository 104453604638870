import { createStore } from 'vuex'

export default createStore({
  state: {
    isZh:true,
    baseUrl:"http://127.0.0.1:8000/",
    show_address:'',
    address:"",
    wu_contract:"0xCF7A936782FF35e18BD4E0B88871A491C3339167",
   // wu_contract:"0x1FC144E0D0C3bC9a71FF7C1a77361DFe53bb3949",
    wu_contract_1:"0xFba4a66D59719d4344C719990F56936542e696e2",
    rouse_contract:"0x9510F9cB516F88B03b68426EDB5F5525bAe2F8Fd",  // RousePro.0
  //  rouse_contract:"0x2d9867aCcC2BC378eea8b803fAC0Db4Cc9ebF3a0",  // RousePro.0
    rouse_contract_1:"0x4b81f6016aD33486CAdFc65bc37E673493739cbE",    // rouse1.0
    homeTabsCurrentIndex:0,
  },
  getters: {
  },
  mutations: {
      setHomeTabsCurrentIndex(state, payload) {
          state.homeTabsCurrentIndex = payload;
      },
    setShowAddress(state,addr){
     state.show_address=addr
    },
    setIsZh(state,b){
      state.isZh=b
     },
    setAddress(state,addr){
      state.address=addr
     },
     setActive(state,index){
      state.active=index
     }
  },
  actions: {
  },
  modules: {
  }
})
