import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),

  },
  {
    path: '/nav',
    name: 'nav',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NavBar.vue'),
    meta: { showTabbar: true }
  },
  {
    path: '/pro',
    name: 'pro',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/xq.vue'),
    meta: { showTabbar: true }
  },
  {
    path: '/me',
    name: 'me',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/User.vue'),
  },
  {
    path: '/faq',
    name: 'faq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Faq.vue'),
    meta: { showTabbar: true }
  },
  {
    path: '/srouse',
    name: 'srouse',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Srouse.vue'),
    meta: { showTabbar: true }
  },
  {
    path: '/category',
    name: 'CategoryList',
    component: () => import(/* webpackChunkName: "about" */ '../views/category/Category.vue'),
    meta: { showTabbar: true }
  },
  {
    path: '/cateset',
    name: 'CateSet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CateSet.vue'),
    meta: { showTabbar: true }
  },
  {
    path: '/detail',
    name: 'DetailInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/detail/Detail'),
    meta: { showTabbar: true }
  },
]


const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history:createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {

next();

/*  document.title = to.meta.title*/

})

export default router
