<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png">
    <Connect msg="Welcome to Your Vue.js App"/> -->
    <mzh-head v-if="!$route.meta.showTabbar"></mzh-head>
   
      <router-view />

  </div>
</template>

<script>

import MzhHead from './components/head.vue'
//import {useI18n} from 'vue-i18n'

export default {
  name: 'App',
  components: {
    MzhHead,
  },
  mounted() {
    //const {t} = useI18n();


  },
  created() {
    console.log(this.$store.state.address)
  },
}
</script>

<style>
body {
  background-color: #F7F7F7;
  font-family: "serif";
  font-size: 15px;
}

.container {

flex-direction: column;
background-color: #FFFFFF;
justify-content: center;
align-items: center;
max-width: 550px;
margin: auto;


}

#app {
  background-color: #F7F7F7;
  color: #000000;
  font-size: 18px;
  
}
.van-nav-bar__content {

height: 60px !important;
}
.van-popover__wrapper {
 
    width: 100%;
}


.van-popover--dark .van-popover__content {
  background-color: #2a2e64 !important;
}

.van-popover--dark {
  color: #ffffff;
  background-color: #2a2e64 !important;
}

.van-popover--dark .van-popover__arrow {
  color: #2a2e64 !important;
}

[class*=van-hairline]::after {
  position: absolute;
  box-sizing: border-box;
  content: ' ';
  pointer-events: none;
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%;
  border: 0 solid #7d8da6;
  -webkit-transform: scale(.5);
  transform: scale(.5);
}

.van-hairline--bottom::after {
  border-bottom-width: 1px;
}

.van-tabbar {
  background-color: #16193A;
}

.van-tabbar-item--active {
  background-color: #16193A;
}
</style>
