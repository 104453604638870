/*
//import Vue from 'vue'
/!*import App from './App.vue'
import {createApp} from 'vue'*!/

//import VueI18n from 'vue-i18n'

import { createI18n } from 'vue-i18n'

import en from './locales/en';
import zh from './locales/cn';
import tw from './locales/tw';
import ko from './locales/ko';
import vi from './locales/vi';
import jp from './locales/jp';
import ind from './locales/in';
import id from './locales/id';
//import App from "@/App";


//const Vue = createApp(App);
//Vue.use(VueI18n);
const DEFAULT_LANG = 'zh';
//const DEFAULT_LANG = 'en';
const LOCALE_KEY = 'localeLanguage';
const locales = {
  zh: {
    ...zh,
  },
  tw: {
    ...tw,
  },
  en: {
    ...en,
  },
  ko: {
    ...ko,
  },
  vi: {
    ...vi,
  },
  jp: {
    ...jp,
  },
  ind: {
    ...ind,
  },
  id: {
    ...id,
  }
}

const  i18n = createI18n({
  locale: DEFAULT_LANG,
  messages: locales
})

export const setup = lang => {
  if (lang === undefined) {
    lang = window.localStorage.getItem(LOCALE_KEY);
    if (locales[lang] === undefined) {
      lang = DEFAULT_LANG;
    }
  }

  console.log(lang, "%%%%%%%%%%%%%%%%%%%%%")
  window.localStorage.setItem(LOCALE_KEY, lang);
  
  Object.keys(locales).forEach(lang => {
    document.body.classList.remove(`lang-${lang}`);
  })
  document.body.classList.add(`lang-${lang}`);
  document.body.setAttribute('lang', lang);
 // Vue.config.lang = lang;
  i18n.locale = lang;
}

setup();


// window.i18n = i18n;
export default i18n;
*/


import {createApp} from 'vue';
//import App from './App.vue'
//import VueI18n from 'vue-i18n'
import { createI18n } from 'vue-i18n'
import en from './locales/en';
import zh from './locales/cn';
import tw from './locales/tw';
import ko from './locales/ko';
import vi from './locales/vi';
import jp from './locales/jp';
import ind from './locales/in';
import id from './locales/id';
import App from "./App";


const DEFAULT_LANG = 'en';
const LOCALE_KEY = 'localeLanguage';
const locales = {
  zh: {
    ...zh,
  },
  tw: {
    ...tw,
  },
  en: {
    ...en,
  },
  ko: {
    ...ko,
  },
  vi: {
    ...vi,
  },
  jp: {
    ...jp,
  },
  ind: {
    ...ind,
  },
  id: {
    ...id,
  }
}

export const  i18n = createI18n({
  locale: DEFAULT_LANG,
  messages: locales
})
const Vue = createApp(App).use(i18n);
/*const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: locales
})*/

export const setup = lang => {
  if (lang === undefined) {
    lang = window.localStorage.getItem(LOCALE_KEY);
    if (locales[lang] === undefined) {
      lang = DEFAULT_LANG;
    }
  }
  window.localStorage.setItem(LOCALE_KEY, lang);

  Object.keys(locales).forEach(lang => {
    document.body.classList.remove(`lang-${lang}`);
  })
  document.body.classList.add(`lang-${lang}`);
  document.body.setAttribute('lang', lang);
  Vue.config.lang = lang;
  i18n.locale = lang;
}

setup();

console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
// window.i18n = i18n;
//export default i18n;

export default locales;
